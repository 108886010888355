import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    show: "closed",
    filterProperties: [],
    filterGroups: [],
    tableFilter: [],
    allProperties: [],
    resetResponses: 0,
  },
  reducers: {
    filterStatus: (state, action) => {
      state.show = action.payload;
    },
    filterProperties: (state, action) => {
      state.filterProperties = action.payload;
    },
    filterGroups: (state, action) => {
      state.filterGroups = action.payload;
    },
    tableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setAllProperties: (state, action) => {
      state.allProperties = action.payload;
    },
    resetFilters: (state) => {
      state.filterGroups = [];
      state.filterProperties = [];
      state.tableFilter = [];
      state.allProperties = [];
    },
    setResetResponses: (state) => {
      state.resetResponses += 1;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  filterStatus,
  filterProperties,
  filterGroups,
  tableFilter,
  resetFilters,
  setAllProperties,
  setResetResponses,
} = filterSlice.actions;

export default filterSlice.reducer;
