import React from "react";
import FlagIcon from "@mui/icons-material/Flag";

const FlagChart = () => {
  const data = [
    {
      name: "Adam Parks Apartments",
      occupancy: true,
      leasing: false,
      maintenance: true,
      collections: true,
      budget: true,
    },
    {
      name: "Cantfield Apartments, LLC.",
      occupancy: false,
      leasing: true,
      maintenance: false,
      collections: true,
      budget: false,
    },
    {
      name: "Draper Lofts Apartments",
      occupancy: false,
      leasing: false,
      maintenance: true,
      collections: false,
      budget: true,
    },
    {
      name: "Grover Cleveland Apartments",
      occupancy: true,
      leasing: true,
      maintenance: false,
      collections: true,
      budget: false,
    },
    {
      name: "Halfmoon Garden Apartments LLC.",
      occupancy: false,
      leasing: false,
      maintenance: false,
      collections: true,
      budget: false,
    },
  ];

  const displayFlag = (variable) => {
    if (variable) return <FlagIcon sx={{ color: "#BA0101" }} />;
    else return null;
  };

  return (
    <div className="container-table">
      <div className="wrapper">
        <table>
          <thead>
            <tr>
              <th className="subparent">Property</th>
              <th>Occupancy</th>
              <th>Leasing</th>
              <th>Maintenance</th>
              <th>Collections</th>
              <th>Budget</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{item?.name}</td>
                <td>{displayFlag(item?.occupancy)}</td>
                <td>{displayFlag(item?.leasing)}</td>
                <td>{displayFlag(item?.maintenance)}</td>
                <td>{displayFlag(item?.collections)}</td>
                <td>{displayFlag(item?.budget)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FlagChart;
