import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getProperties } from "../../api/api";
import { useDispatch } from "react-redux";
import { editPropertiesList } from "../../storage/stateSlice";
import "./styles.scss";
import { filterProperties } from "../../storage/filterSlice";
const ENUM_OPTIONS = {
  DO_NOTHING:0,
  CHECKED:1,
  UNCHECKED:2,
}
export const PropertiesForm = ({
  selected = [],
  properties = [],
  edit = false,
  checkAll = ENUM_OPTIONS.DO_NOTHING,
  filter = false,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        await getProperties().then((res) => {
          if (edit) {
            handleCheckSelected(res.properties)
          } else {
            setData(res.properties);
          }
        });
      } catch (error) {
        if (
          error.response.data.resetUser &&
          error.response.data.msg === "Token invalid"
        ) {
          // Handle token invalid error
        }
      }
    };

    if (!filter) fetchProperties();
    else {
      //setData(selected);
      if(selected.length > 0){
        handleCheckSelected(properties)
      }else{
        setData(properties)
      }
    }
  }, []);

  useEffect(() => {
    dispatch(editPropertiesList(data));

    if (filter) dispatch(filterProperties(data));
  }, [data, dispatch]);

  useEffect(() => {
    if (checkAll === ENUM_OPTIONS.CHECKED) {
      setData((prevData) =>
        prevData.map((item) => ({
          ...item,
          value: 1,
        }))
      );
    } 
    if(checkAll === ENUM_OPTIONS.UNCHECKED) {
      setData((prevData) =>
        prevData.map((item) => ({
          ...item,
          value: 0,
        }))
      );
    }

    //if(selected.length>0)
    //  handleCheckSelected(properties)
  }, [checkAll]);

  const handleCheckSelected = (array) => {
    const updatedArray = [...array];
            for (let i = 0; i < updatedArray?.length; i++) {
              for (let j = 0; j < selected?.length; j++) {
                if (updatedArray[i]?.id === selected[j]?.id) {
                  // Create a new object with the updated value property
                  const updatedObject = {
                    ...updatedArray[i],
                    value: 1,
                  };
                  // Replace the object in the updatedArray
                  updatedArray[i] = updatedObject;
                }
              }
            }
    setData(updatedArray);
  }

  const handleChange = (event, id) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.propertyID === id
          ? { ...item, value: event.target.checked ? 1 : 0 }
          : item
      )
    );
  };

  return (
    <div className="list-property-content">
      {data?.map((value, index) => {
        return (
          <div key={index} className="item">
            <FormControlLabel
              value={value?.propertyID.toString()}
              control={
                <Checkbox
                  id={value?.propertyID.toString()}
                  name={value?.propertyID.toString()}
                  checked={value?.value === 1}
                  onChange={(event) => handleChange(event, value?.propertyID)}
                  sx={{
                    color: "#003A5F",
                    "&.Mui-checked": {
                      color: "#003A5F",
                    },
                  }}
                />
              }
              label={value?.propertyName}
              labelPlacement="start"
            />
            <div className="line" style={{ margin: "0.2rem 0" }}></div>
          </div>
        );
      })}
    </div>
  );
};
