import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentColumn, setCurrentProperty, setShowPreviewNotes, setCurrentColumnValue, setbldgValue, setAccountValue, setSourceValue } from "../storage/noteSlice";

const useNoteVisibility = (listNotes = [], loader = true) => {
  const dispatch = useDispatch();
  const showNotesPreviewStatus = useSelector((state) => state.note.showPreviewNotes)

  const [componentPosition, setComponentPosition] = useState({ x: 0, y: 0 });
  const [showComponent, setShowComponent] = useState(false);
  const [notes, setNotes] = useState([]);

  const handleNote = ( property, column, columnValue, bldg, account, source) => (event) => {
    if(property && column && columnValue){
    const { clientX, clientY } = event;
    setComponentPosition({ x: clientX, y: clientY });
    setShowComponent(true);
    dispatch(setShowPreviewNotes(true));

    if(bldg){
      handleNotesFilteredByUnitID(property, column, columnValue, bldg);
    }else if(account){
      handleNotesFilteredByAccount(property, column, columnValue, account)
    }else if(source){
      handleNotesFilteredBySource(property, column, columnValue, source)
    }else{
handleNotesFiltered(property, column, columnValue);
    }
  }
  };

  const handleNotesFiltered = (property, column, columnValue) => {
    const notesFiltered = listNotes.filter(
      (note) => note.propertyID === property && note.column === column
    );

    dispatch(setCurrentProperty(property));
    dispatch(setCurrentColumn(column))
    dispatch(setCurrentColumnValue(columnValue))
    setNotes(notesFiltered);
  };

  const handleNotesFilteredByUnitID = (property, column, columnValue, bldg) => {
    const notesFiltered = listNotes.filter(
      (note) => note.propertyID === property && note.column === column && note.bldg === bldg
    );

    dispatch(setCurrentProperty(property));
    dispatch(setCurrentColumn(column))
    dispatch(setCurrentColumnValue(columnValue))
    dispatch(setbldgValue(bldg))
    setNotes(notesFiltered);
  };

  const handleNotesFilteredBySource = (property, column, columnValue, source) => {
    const notesFiltered = listNotes.filter(
      (note) => note.propertyID === property && note.column === column && note.source === source
    );

    dispatch(setCurrentProperty(property));
    dispatch(setCurrentColumn(column))
    dispatch(setCurrentColumnValue(columnValue))
    dispatch(setSourceValue(source))
    setNotes(notesFiltered);
  };

  const handleNotesFilteredByAccount = (property, column, columnValue, account) => {
    const notesFiltered = listNotes.filter(
      (note) => note.propertyID === property && note.column === column && note.account === account
    );

    dispatch(setCurrentProperty(property));
    dispatch(setCurrentColumn(column))
    dispatch(setCurrentColumnValue(columnValue))
    dispatch(setAccountValue(account))
    setNotes(notesFiltered);
  };

  const handleCheckFlag = (property, column) => {
    if (!loader) {
      // Check if the current item's propertyID and column match with any entry in listNotes
      const hasFlag = listNotes.some(
        (note) => note.propertyID === property && note.column === column
      );
      if (hasFlag) return "hasFlag";
      else return "";
    } else return "";
  };

  const handleCheckFlagforUnits = (property, column, bldg) => {
    if (!loader) {
      // Check if the current item's propertyID and column match with any entry in listNotes
      const hasFlag = listNotes.some(
        (note) => note.propertyID === property && note.column === column && note.bldg === bldg
      );

      if (hasFlag) return "hasFlag";
      else return "";
    } else return "";
  };

  const handleCheckFlagforAccounts = (property, column, account) => {
    if (!loader) {
      // Check if the current item's propertyID and column match with any entry in listNotes
      const hasFlag = listNotes.some(
        (note) => note.propertyID === property && note.column === column && note.account === account
      );

      if (hasFlag) return "hasFlag";
      else return "";
    } else return "";
  };

  const handleCheckFlagforsource = (property, column, source) => {
    if (!loader) {
      // Check if the current item's propertyID and column match with any entry in listNotes
      const hasFlag = listNotes.some(
        (note) => note.propertyID === property && note.column === column && note.source === source
      );

      if (hasFlag) return "hasFlag";
      else return "";
    } else return "";
  };

  useEffect(() => {
    if (!showNotesPreviewStatus) {
      setShowComponent(false);
      dispatch(setShowPreviewNotes(false));
    }
  }, [showNotesPreviewStatus]);

  return {
    showComponent,
    componentPosition,
    notes,
    handleNote,
    handleNotesFiltered,
    handleCheckFlag,
    handleNotesFilteredByUnitID,
    handleCheckFlagforUnits,
    handleNotesFilteredByAccount,
    handleCheckFlagforAccounts,
    handleCheckFlagforsource
  };
};

export default useNoteVisibility;
