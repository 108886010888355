import React, { useState, useEffect, useRef } from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import { Tooltip, Typography } from "@mui/material";
import tooltips from "../../../../config/tooltips.json";
import Fade from "@mui/material/Fade";

const Renewals = ({ data }) => {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const vwToPx = (vw) => (vw * width) / 100;

  let rowsData = data.map((val) => {
    return {
      id: val.id,
      propertyID: val.propertyID,
      name: val.name,
      month1PendingValue: val.month1.pending,
      month1notSentValue: val.month1.notSent,
      month2PendingValue: val.month2.pending,
      month2notSentValue: val.month2.notSent,
      month3PendingValue: val.month3.pending,
      month3notSentValue: val.month3.notSent,
      month4PendingValue: val.month4.pending,
      month4notSentValue: val.month4.notSent,
      mtm: val.mtm,
    };
  });

  const columnNames = [
    { name: "Property", type: "singleSelect", width: vwToPx(20) },
    {
      name: `Pending Due In ${data[0].month1.pendingDueIn}`,
      type: "number",
      width: vwToPx(8),
    },
    {
      name: `Not Sent: sent in ${data[0].month1.notSentIn}`,
      type: "number",
      width: vwToPx(8),
    },
    {
      name: `Pending Due In ${data[0].month2.pendingDueIn}`,
      type: "number",
      width: vwToPx(9),
    },
    {
      name: `Not Sent: sent in ${data[0].month2.notSentIn}`,
      type: "number",
      width: vwToPx(9),
    },
    {
      name: `Pending Due In ${data[0].month3.pendingDueIn}`,
      type: "number",
      width: vwToPx(9),
    },
    {
      name: `Not Sent: sent in ${data[0].month3.notSentIn}`,
      type: "number",
      width: vwToPx(9),
    },
    {
      name: `Pending Due In ${data[0].month4.pendingDueIn}`,
      type: "number",
      width: vwToPx(9),
    },
    {
      name: `Not Sent: sent in ${data[0].month4.notSentIn}`,
      type: "number",
      width: vwToPx(9),
    },
    { name: `Month to Month`, type: "number", width: vwToPx(9) },
  ];

  const columnGroupingModel = [
    {
      groupId: `${data[0].month1.name}`,
      headerName: `${data[0].month1.name}`,
      headerAlign: "center",
      children: [
        { field: "month1PendingValue" },
        { field: "month1notSentValue" },
      ],
    },
    {
      groupId: `${data[0].month2.name}`,
      headerName: `${data[0].month2.name}`,
      headerAlign: "center",
      children: [
        { field: "month2PendingValue" },
        { field: "month2notSentValue" },
      ],
    },
    {
      groupId: `${data[0].month3.name}`,
      headerName: `${data[0].month3.name}`,
      headerAlign: "center",
      children: [
        { field: "month3PendingValue" },
        { field: "month3notSentValue" },
      ],
    },
    {
      groupId: `${data[0].month4.name}`,
      headerName: `${data[0].month4.name}`,
      headerAlign: "center",
      children: [
        { field: "month4PendingValue" },
        { field: "month4notSentValue" },
      ],
    },
  ];

  const thresHoldValues = {
    month1PendingValue: {
      thresHoldValue: "Month1PendingBackByX",
    },
    month1notSentValue: {
      thresHoldValue: "Month1NotSentByX",
    },
    month2PendingValue: {
      thresHoldValue: "Month2PendingBackByX",
    },
    month2notSentValue: {
      thresHoldValue: "Month2NotSentByX",
    },
    month3PendingValue: {
      thresHoldValue: "Month3PendingBackByX",
    },
    month3notSentValue: {
      thresHoldValue: "Month3NotSentByX",
    },
    month4PendingValue: {
      thresHoldValue: "Month4PendingBackByX",
    },
    month4notSentValue: {
      thresHoldValue: "Month4NotSentByX",
    },
    mtm: {
      thresHoldValue: "MonthToMonth",
    },
  };

  let { columenGen, thresHolds } = useMUIFilter(rowsData, columnNames);

  let columns = columenGen();

  let columnTooltips = columns.map((data, index) => {
    data.renderHeader = (params) => {
      return (
        <Tooltip
          placement="top"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <React.Fragment>
              <Typography color="inherit">
                {data.headerName.includes("Due")
                  ? tooltips["Renewals"][0].tooltip.header
                  : tooltips["Renewals"][1].tooltip.header}
              </Typography>
            </React.Fragment>
          }
        >
          <p className="MuiDataGrid-columnHeaderTitle">{data.headerName}</p>
        </Tooltip>
      );
    };
    return data;
  });

  const height = useTableHeight(data, 90, "530px");
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "Renewals"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const { getThresholdData } = useThreshold({
    view: "occupancy",
    table: "Renewals",
    loader,
  });

  return (
    <div
      className="container-table"
      style={{ height: height }}
      ref={elementRef}
    >
      <DataGridPremium
                   sx={{
                    "&&& .MuiDataGrid-aggregationColumnHeader": {
                      position: "relative",
                    },
                    '&&& .MuiDataGrid-aggregationColumnHeaderLabel': {
                      position: "absolute",
                      top: 0,
                      left: "100%",
                      transform: "translateX(5px)",
                    },
                  }}
        className="grid-style"
        slots={{
          toolbar: ExportToolbar,
        }}
        rows={rowsData}
        columns={columnTooltips}
        disableColumnMenu={true}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        hideFooter
        disableVirtualization
        disableColumnSelector
        getCellClassName={(params) => {
          return `${thresHolds(params, getThresholdData, thresHoldValues)}`;
        }}
        initialState={{
          aggregation: {
            model: {
              month1PendingValue: "sum",
              month1notSentValue: "sum",
              month2PendingValue: "sum",
              month2notSentValue: "sum",
              month3PendingValue: "sum",
              month3notSentValue: "sum",
              month4PendingValue: "sum",
              month4notSentValue: "sum",
              mtm: "sum",
            },
          },
        }}
      />
    </div>
  );
};

export default Renewals;

// lagecy code
// import React from "react";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const Renewals = ({ data }) => {
//   const totalmtm = data?.reduce((acc, curr) => acc + curr?.mtm, 0);
//   const totalseptP = data?.reduce(
//     (acc, curr) => acc + curr?.month1?.pending,
//     0
//   );
//   const totaloctP = data?.reduce((acc, curr) => acc + curr?.month2?.pending, 0);
//   const totalnovP = data?.reduce((acc, curr) => acc + curr?.month3?.pending, 0);
//   const totaldecP = data?.reduce((acc, curr) => acc + curr?.month4?.pending, 0);

//   const totalseptNS = data?.reduce(
//     (acc, curr) => acc + curr?.month1?.notSent,
//     0
//   );
//   const totaloctNS = data?.reduce(
//     (acc, curr) => acc + curr?.month2?.notSent,
//     0
//   );
//   const totalnovNS = data?.reduce(
//     (acc, curr) => acc + curr?.month3?.notSent,
//     0
//   );
//   const totaldecNS = data?.reduce(
//     (acc, curr) => acc + curr?.month4?.notSent,
//     0
//   );

//   const columnNames = [
//     "Property",
//     `Pending Back By 8/1`,
//     `Not Sent: Sent By 6/1`,
//     `Pending Back By 9/1`,
//     `Not Sent: Sent By 7/1`,
//     `Pending Back By 10/1`,
//     `Not Sent: Sent By 8/1`,
//     `Pending Back By 11/1`,
//     `Not Sent: Sent By 9/1`,
//     "Month to Month",
//   ];

//   const height = useTableHeight(data, 90, "530px");
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "Renewals"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'occupancy', table: 'Renewals', loader})

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note notes={notes} columnNames={columnNames} table="Renewals" />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th colSpan={2} className="subparent">
//                 {data[0]?.month1?.name}
//               </th>
//               <th colSpan={2} className="subparent">
//                 {data[0]?.month2?.name}
//               </th>
//               <th colSpan={2} className="subparent">
//                 {data[0]?.month3?.name}
//               </th>
//               <th colSpan={2} className="subparent">
//                 {data[0]?.month4?.name}
//               </th>
//               <th>Month to Month</th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th>{`Pending Due in ${data[0]?.month1?.pendingDueIn}`}</th>
//               <th>{`Not Sent: sent in ${data[0]?.month1?.notSentIn}`}</th>
//               <th>{`Pending Due in ${data[0]?.month2?.pendingDueIn}`}</th>
//               <th>{`Not Sent: sent in ${data[0]?.month2?.notSentIn}`}</th>
//               <th>{`Pending Due in ${data[0]?.month3?.pendingDueIn}`}</th>
//               <th>{`Not Sent: sent in ${data[0]?.month3?.notSentIn}`}</th>
//               <th>{`Pending Due in ${data[0]?.month4?.pendingDueIn}`}</th>
//               <th>{`Not Sent: sent in ${data[0]?.month4?.notSentIn}`}</th>
//               <th></th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0, item?.name)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.month1?.pending)}
//                   className={`${getThresholdData(parseInt(item?.month1?.pending), 'Month1PendingBackByX')}
//                   ${handleCheckFlag(item?.propertyID, 1)}`}
//                 >
//                   {item?.month1?.pending}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.month1?.notSent)}
//                   className={`${getThresholdData(parseInt(item?.month1?.notSent), 'Month1NotSentByX')}
//                   ${handleCheckFlag(item?.propertyID, 2)}`}
//                 >
//                   {item?.month1?.notSent}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.month2?.pending)}
//                   className={`${getThresholdData(parseInt(item?.month2?.pending), 'Month2PendingBackByX')}
//                   ${handleCheckFlag(item?.propertyID, 3)}`}
//                 >
//                   {item?.month2?.pending}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.month2?.notSent)}
//                   className={`${getThresholdData(parseInt(item?.month2?.notSent), 'Month2NotSentByX')}
//                   ${handleCheckFlag(item?.propertyID, 4)}`}
//                 >
//                   {item?.month2?.notSent}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.month3?.pending)}
//                   className={`${getThresholdData(parseInt(item?.month3?.pending), 'Month3PendingBackByX')}
//                   ${handleCheckFlag(item?.propertyID, 5)}`}
//                 >
//                   {item?.month3?.pending}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.month3?.notSent)}
//                   className={`${getThresholdData(parseInt(item?.month3?.notSent), 'Month3NotSentByX')}
//                   ${handleCheckFlag(item?.propertyID, 6)}`}
//                 >
//                   {item?.month3?.notSent}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7,item?.month4?.pending)}
//                   className={`${getThresholdData(parseInt(item?.month4?.pending), 'Month4PendingBackByX')}
//                   ${handleCheckFlag(item?.propertyID, 7)}`}
//                 >
//                   {item?.month4?.pending}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 8,item?.month4?.notSent)}
//                   className={`${getThresholdData(parseInt(item?.month4?.notSent), 'Month4NotSentByX')}
//                   ${handleCheckFlag(item?.propertyID, 8)}`}
//                 >
//                   {item?.month4?.notSent}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 9, item?.mtm)}
//                   className={`${getThresholdData(parseInt(item?.mtm), 'MonthToMonth')}
//                   ${handleCheckFlag(item?.propertyID, 9)}`}
//                 >
//                   {item?.mtm}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{totalseptP}</td>
//               <td>{totalseptNS}</td>
//               <td>{totaloctP}</td>
//               <td>{totaloctNS}</td>
//               <td>{totalnovP}</td>
//               <td>{totalnovNS}</td>
//               <td>{totaldecP}</td>
//               <td>{totaldecNS}</td>
//               <td>{totalmtm}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Renewals;
