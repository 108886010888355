import React, { useState, useRef, useEffect } from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import Note from "../../../../components/note";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import tooltips from "../../../../config/tooltips.json";
import { Tooltip, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';

const EvBTable = ({ data }) => {

  const vwToPx = (vw) => (vw * width) / 100;
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const columnNames = [
    { name: "Property", type: "singleSelect", width: vwToPx(18)},
    { name: "Direct Expenses Actual", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Direct Expenses Budget", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Direct Expenses $ Variance", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Direct Expenses % Variance", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    { name: "Payroll/ Admin Actual", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Payroll/ Admin Budget", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Payroll/ Admin $ Variance", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Payroll/ Admin % Variance", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    { name: "Total Expenses Actual", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Total Expenses Budget", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Total Expenses $ Variance", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    { name: "Total Expenses % Variance", type: "number", width: vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
  ];

  data = data.map((val, index) => {
    return {
      id: index,
      propertyID: val.propertyID,
      name: val.name,
      adeMTD: val.adeMTD,
      bdeMTD: val.bdeMTD,
      devM: val.devM,
      devP: val.devP,
      adpeMTD: val.adpeMTD,
      bapeMTD: val.bapeMTD,
      apevMTDM: val.apevMTDM,
      apevMTDP: val.apevMTDP,
      ateMTD: val.ateMTD,
      bteMTD: val.bteMTD,
      tevM: val.tevM,
      evP: val.evP,
    };
  });


  const columnGroupingModel = [
    {
      groupId: `Direct Expenses`,
      headerName: `Direct Expenses	`,
      headerAlign: 'center',
      children: [{ field: 'adeMTD' }, { field: 'bdeMTD' }, { field: 'devM' }, { field: 'devP' }],
    },
    {
      groupId: `Payroll/ Admin`,
      headerName: `Payroll/ Admin`,
      headerAlign: 'center',
      children: [{ field: 'adpeMTD' }, { field: 'bapeMTD' }, { field: 'apevMTDM' }, { field: 'apevMTDP' }],
    },
    {
      groupId: `Total Expenses`,
      headerName: `Total Expenses`,
      headerAlign: 'center',
      children: [{ field: 'ateMTD' }, { field: 'bteMTD' }, { field: 'tevM' }, { field: 'evP' }],
    },
  ]

  const height = useTableHeight(data, 90, "550px");
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "Expenses-vs-Budgets"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const { getThresholdData } = useThreshold({
    view: "budget",
    table: "ExpenseVSBudgets",
    loader,
  });

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
    // "Expenses vs Budgets"
  );

  const thresHoldValues = {
    devP: {
      thresHoldValue: "DirectExpensesPercentVariance",
    },
    apevMTDP: {
      thresHoldValue: "PayrollAdminPercentVariance",
    },
    evP: {
      thresHoldValue: "TotalExpensesPercentVariance",
    },
  };

  let columns = columenGen();

  columns = columns.map((data, index) => {
    let headerName
    if(data.headerName.includes('Actual')){
      headerName = 'Actual'
    }else if(data.headerName.includes('Budget')){
      headerName = 'Budget'
    }else if(data.headerName.includes('$ Variance')){
      headerName = '$ Variance'
    }else if(data.headerName.includes('% Variance')){
      headerName = '% Variance'
    }else{
      headerName = data.headerName
    }
  
    data.renderHeader = () => {
      if(tooltips['Expenses vs Budgets'][index]?.tooltip?.header !== null){
      return (
        <Tooltip
          placement="top"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <React.Fragment>
  <Typography color="inherit">{tooltips['Expenses vs Budgets'][index]?.tooltip?.header}</Typography>
  {tooltips['Expenses vs Budgets'][index]?.tooltip?.text}
            </React.Fragment>
          }
        >
          <p className="MuiDataGrid-columnHeaderTitle">{headerName}</p>
        </Tooltip>
      );
    }else{
return (<p className="MuiDataGrid-columnHeaderTitle">{headerName}</p>)
    }
  };
    return data;
  })

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="Expenses-vs-Budgets"
          />
        </div>
      )}
      <DataGridPremium
        className="grid-style"
        rows={data}
        columns={columns}
        pinnedColumns={{ left: ["name"] }}
        slots={{ toolbar: ExportToolbar }}
        disableColumnMenu={true}
        getCellClassName={(params) => {
          return `${thresHolds(
            params,
            getThresholdData,
            thresHoldValues
          )} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`;
        }}
        onCellClick={(cell, event) => {
          handleNote(
            cell.row.propertyID,
            cell.colDef.colNum,
            cell.formattedValue
          )(event);
        }}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        disableVirtualization
        hideFooter
        disableColumnSelector
        initialState={{
          aggregation: {
            model: {
              adeMTD:'sum',
              bdeMTD:'sum',
              devM:'sum',
              devP:'avg',
              adpeMTD:'sum',
              bapeMTD:'sum',
              apevMTDM:'sum',
              apevMTDP:'avg',
              ateMTD:'sum',
              bteMTD:'sum',
              tevM:'sum',
              evP:'avg'
            },
          },
        }}
      />
    </div>
  );
};

export default EvBTable;

//Legacy Code
// import React from "react";
// import { money, percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import Note from "../../../../components/note";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const EvBTable = ({ data }) => {
//   const columnNames = [
//     "Property",
//     "Direct Expenses Actual",
//     "Direct Expenses Budget",
//     "Direct Expenses $ Variance",
//     "Direct Expenses % Variance",
//     "Payroll/ Admin Expenses Actual",
//     "Payroll/ Admin Expenses Budget",
//     "Payroll/ Admin Expenses $ Variance",
//     "Payroll/ Admin Expenses % Variance",
//     "Total Expenses MTD Actual",
//     "Total Expenses MTD Budget",
//     "Total Expenses MTD $ Variance",
//     "Total Expenses MTD % Variance",
//   ];
//   const height = useTableHeight(data, 90, "550px");
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "Expenses-vs-Budgets"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const { getThresholdData } = useThreshold({
//     view: "budget",
//     table: "ExpenseVSBudgets",
//     loader,
//   });

//   const totaladeMTD = data.reduce((acc, curr) => acc + curr?.adeMTD, 0);
//   const totalbdeMTD = data.reduce((acc, curr) => acc + curr?.bdeMTD, 0);
//   const totaldevM = data.reduce((acc, curr) => acc + curr?.devM, 0);
//   const totaldevP = data.reduce((acc, curr) => acc + curr?.devP, 0);
//   const totaladpeMTD = data.reduce((acc, curr) => acc + curr?.adpeMTD, 0);
//   const totalbapeMTD = data.reduce((acc, curr) => acc + curr?.bapeMTD, 0);
//   const totalapevMTDM = data.reduce((acc, curr) => acc + curr?.apevMTDM, 0);
//   const totalapevMTDP = data.reduce((acc, curr) => acc + curr?.apevMTDP, 0);
//   const totalateMTD = data.reduce((acc, curr) => acc + curr?.ateMTD, 0);
//   const totalbteMTD = data.reduce((acc, curr) => acc + curr?.bteMTD, 0);
//   const totaltevM = data.reduce((acc, curr) => acc + curr?.tevM, 0);

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="Expenses-vs-Budgets"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th className="subparent" colSpan={4}>
//                 Direct Expenses
//               </th>
//               <th className="subparent" colSpan={4}>
//                 Payroll/ Admin Expenses
//               </th>
//               <th className="subparent" colSpan={4}>
//                 Total Expense MTD
//               </th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th>Actual</th>
//               <th>Budget</th>
//               <th>$ Variance</th>
//               <th>% Variance</th>
//               <th>Actual</th>
//               <th>Budget</th>
//               <th>$ Variance</th>
//               <th>% Variance</th>
//               <th>Actual</th>
//               <th>Budget</th>
//               <th>$ Variance</th>
//               <th>% Variance</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${
//                     index % 2 === 0 ? "white" : ""
//                   }`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.adeMTD, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 1)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.adeMTD)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.bdeMTD, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 2)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.bdeMTD)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.devM, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 3)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.devM)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.devP, columnNames)}
//                   className={`${getThresholdData(
//                     parseInt(item?.devP),
//                     "DirectExpensesPercentVariance"
//                   )} ${handleCheckFlag(item?.propertyID, 4)}`}
//                 >
//                   {percentage(item?.devP)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.adpeMTD, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 5)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.adpeMTD)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.bapeMTD, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 6)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.bapeMTD)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7, item?.apevMTDM, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 7)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.apevMTDM)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 8, item?.apevMTDP, columnNames)}
//                   className={`${getThresholdData(
//                     parseInt(item?.apevMTDP),
//                     "PayrollAdminPercentVariance"
//                   )} ${handleCheckFlag(item?.propertyID, 8)}`}
//                 >
//                   {percentage(item?.apevMTDP)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 9, item?.ateMTD, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 9)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.ateMTD)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 10, item?.bteMTD, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 10)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.bteMTD)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 11, item?.tevM, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 11)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.tevM)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 12, item?.apevMTDP, columnNames)}
//                   className={`${getThresholdData(
//                     parseInt(item?.apevMTDP),
//                     "TotalExpensesPercentVariance"
//                   )} ${handleCheckFlag(item?.propertyID, 12)}`}
//                 >
//                   {percentage(item?.apevMTDP)}%
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td style={{textAlign:'right'}}>{money(totaladeMTD)}</td>
//               <td style={{textAlign:'right'}}>{money(totalbdeMTD)}</td>
//               <td style={{textAlign:'right'}}>{money(totaldevM)}</td>
//               <td>{percentage(totaldevP / data.length)}%</td>
//               <td style={{textAlign:'right'}}>{money(totaladpeMTD)}</td>
//               <td style={{textAlign:'right'}}>{money(totalbapeMTD)}</td>
//               <td style={{textAlign:'right'}}>{money(totalapevMTDM)}</td>
//               <td>{percentage(totalapevMTDP / data.length)}%</td>
//               <td style={{textAlign:'right'}}>{money(totalateMTD)}</td>
//               <td style={{textAlign:'right'}}>{money(totalbteMTD)}</td>
//               <td style={{textAlign:'right'}}>{money(totaltevM)}</td>
//               <td>{percentage(totalapevMTDP / data.length)}%</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default EvBTable;
