import React, { useEffect,useState } from "react";
import "../../styles.scss";
import "./leasing.scss";
import { useSelector } from "react-redux";
import CurrentAvailability from "./tables/currentAvailability";
import RentalTimeline from "./tables/rentalTimeline";
import LeadConversion from "./tables/leadConversion";
import LeasingSucess from "./tables/leasingSuccess";
import { useNavigate } from "react-router-dom";
import {
  getConversion,
  getCurrentAvailability,
  getRentalTimeline,
  getSuccess,
} from "../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import FilterButton from "../../components/filterButton";
import useFilterResponse from "../../hooks/useFilterResponse";

const Leasing = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);

const {response: currentAvailabilityData, loader: currentAvailabilityLoader, fetchData: refetchDataCA} = useFilterResponse(getCurrentAvailability, 'currentAvailableUnits')
const {response: conversionData, loader: conversionLoader, fetchData: refetchDataC} = useFilterResponse(getConversion, 'conversion')
const {response: successData, loader: successLoader, fetchData: refetchDataS} = useFilterResponse(getSuccess, 'leasing')
const {response: rentalTimelineData, loader: rentalTimelineLoader, fetchData: refetchDataRT} = useFilterResponse(getRentalTimeline, 'rentalTimeline')

useEffect(() => {
refetchDataCA()
refetchDataC()
refetchDataS()
refetchDataRT()
}, [localStorage.getItem('@properties-filter')]);

  const handleRedirectAvailabilityButton = () => navigate("/availability");
  const handleRedirectLeadconversionButton = () => navigate("/lead-conversion");

  return (
    <>
      <div id="main">
        <FilterButton/>
        <div className={`content ${status}`}>
          <div className="leasing-container">
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Current Availability</h2>
                <button onClick={handleRedirectAvailabilityButton}>
                  MORE DETAILS
                </button>
              </div>
              <div className="line"></div>
              {currentAvailabilityLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <CurrentAvailability data={currentAvailabilityData} />
              )}
            </div>
            <div className={`child ${status}`}>
              <h2>Rental Timeline</h2>
              <div className="line"></div>
              {rentalTimelineLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <RentalTimeline data={rentalTimelineData} />
              )}
            </div>
            <div className={`child ${status}`}>
              <h2>Lead Conversion</h2>
              <div className="line"></div>
              {conversionLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <LeadConversion data={conversionData} />
              )}
            </div>
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Leasing Success</h2>
                <button onClick={handleRedirectLeadconversionButton}>
                  MORE DETAILS
                </button>
              </div>
              <div className="line"></div>
              {successLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <LeasingSucess data={successData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leasing;
