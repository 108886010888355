import React, { useRef, useState, useEffect } from "react";
import { percentage } from "../../../../functions/decimals";
import useTableHeight from "../../../../hooks/useTableHeight";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";

const LeadConversion = ({ data }) => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const vwToPx = (vw) => (vw * width) / 100;

  const columnNames = [
    {name:"Property", type:'singleSelect', width:vwToPx(24)},
    {name:"Leads per Showing", type:'number', width:vwToPx(15)},
    {name:"Showings per App", type:'number', width:vwToPx(15)},
    {name:"App per Approval", type:'number', width:vwToPx(15)},
    {name:"Approved Apps per Lease", type:'number', width:vwToPx(15)},
    {name:"Approved App per move in", type:'number', width:vwToPx(15), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()}`;
    }},
  ];

  const thresHoldValues = {
    leadsPerShowing:{
      thresHoldValue: 'LeadsPerShowing'
    },
    showingsPerApp:{
      thresHoldValue: 'ShowingPerApp'
    },
    appPerApprov:{
      thresHoldValue: 'AppPerApproval'
    },
    approvPerLease:{
      thresHoldValue: 'ApprovedAppsPerLease'
    },
    approvAppPerMoveIn:{
      thresHoldValue: 'ApprovedAppPerMoveIn'
    }
}

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
    "Lead Conversion"
  );

  const apiRef = useGridApiRef();
  let columns = columenGen();
  const height = useTableHeight(data);

  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "LeadConversion"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const {getThresholdData} = useThreshold({view:'leasing', table: 'LeadConversion', loader})

  // if (apiRef?.current?.autosizeColumns) {
  //   apiRef.current.autosizeColumns({
  //     includeHeaders: true,
  //     includeOutliers: true,
  //   });
  // }

  return (
    <div className="container-table__rental" style={{ height: height }}  ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="LeadConversion"
          />
        </div>
      )}

      <DataGridPremium
  className="grid-style"
  apiRef={apiRef}
  rows={data}
  columns={columns}
  onCellClick={(cell,event)=>{handleNote(cell.row.propertyID, cell.colDef.colNum, cell.formattedValue)(event)}}
  getCellClassName={(params) => {
    return `${thresHolds(params, getThresholdData,thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  pinnedColumns={{left: ['name']}}
  disableVirtualization
  hideFooter
  disableColumnSelector
  initialState={{
    aggregation: {
      model: {
        leadsPerShowing: 'sum',
        showingsPerApp: 'sum',
        appPerApprov:'sum',
        approvPerLease:'sum',
        approvAppPerMoveIn:'avg'
      },
    },
  }}
/>
    </div>
  );
};

export default LeadConversion;

// Legacy code
// import React from "react";
// import { percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const LeadConversion = ({ data }) => {
//   const totalLeadsPerShowing = data.reduce(
//     (acc, curr) => acc + curr.leadsPerShowing,
//     0
//   );
//   const totalShowingsPerApp = data.reduce(
//     (acc, curr) => acc + curr.showingsPerApp,
//     0
//   );
//   const totalAppPerApprov = data.reduce(
//     (acc, curr) => acc + curr.appPerApprov,
//     0
//   );
//   const totalApprovPerLease = data.reduce(
//     (acc, curr) => acc + curr.approvPerLease,
//     0
//   );
//   const totalApprovAppPerMoveIn = data.reduce(
//     (acc, curr) => acc + curr.approvAppPerMoveIn,
//     0
//   );
//   const columnNames = [
//     "Property",
//     "Leads per Showing",
//     "Showings per App",
//     "App per Approval",
//     "Approved Apps per Lease",
//     "Approved App per move in",
//   ];

//   const height = useTableHeight(data);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "LeadConversion"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'leasing', table: 'LeadConversion', loader})

//   return (
//     <div className="container-table__rental" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="LeadConversion"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th className="subparent">Leads per Showing</th>
//               <th className="subparent">Showings per App</th>
//               <th className="subparent">App per Approval</th>
//               <th className="subparent">Approved Apps per Lease</th>
//               <th className="subparent">Approved App per move in</th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th colSpan={4}>(Rolling 30 Days)</th>
//               <th>(Rolling 90 Days)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.leadsPerShowing)}
//                   className={`${getThresholdData(parseInt(item?.leadsPerShowing), 'LeadsPerShowing')}
//                   ${handleCheckFlag(item?.propertyID, 1)}`}
//                 >
//                   {Math.round(item?.leadsPerShowing * 10) / 10}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.showingsPerApp)}
//                   className={`${getThresholdData(parseInt(item?.showingsPerApp), 'ShowingPerApp')}
//                   ${handleCheckFlag(item?.propertyID, 2)}`}
//                 >
//                   {Math.round(item?.showingsPerApp * 10) / 10}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.appPerApprov)}
//                   className={`${getThresholdData(parseInt(item?.appPerApprov), 'AppPerApproval')}
//                   ${handleCheckFlag(item?.propertyID, 3)}`}
//                 >
//                   {Math.round(item?.appPerApprov * 10) / 10}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.approvPerLease)}
//                   className={`${getThresholdData(parseInt(item?.approvPerLease), 'ApprovedAppsPerLease')}
//                   ${handleCheckFlag(item?.propertyID, 4)}`}
//                 >
//                   {Math.round(item?.approvPerLease * 10) / 10}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.approvAppPerMoveIn)}
//                   className={`${getThresholdData(parseInt(item?.approvAppPerMoveIn), 'ApprovedAppPerMoveIn')}
//                   ${handleCheckFlag(item?.propertyID, 5)}`}
//                 >
//                   {Math.round(item?.approvAppPerMoveIn * 10) / 10}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{Math.round(totalLeadsPerShowing * 10) / 10}</td>
//               <td>{Math.round(totalShowingsPerApp * 10) / 10}</td>
//               <td>{Math.round(totalAppPerApprov * 10) / 10}</td>
//               <td>{percentage(totalApprovPerLease)}%</td>
//               <td>{Math.round(totalApprovAppPerMoveIn * 10) / 10}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default LeadConversion;

