export const percentage = (number) => {
  if (number === null || number === undefined) {
    return 0;
  }
  const numeroStr = number.toString();

  if (numeroStr.includes(".")) {
    const [integer, decimal] = numeroStr.split(".");

    if (decimal === "0") {
      return parseInt(integer, 10);
    } else {
      return parseFloat(integer + "." + decimal.charAt(0));
    }
  } else {
    return number;
  }
};

export const money = (amount) => {
  const roundedAmount = Math.round(amount * 100) / 100;

  if (roundedAmount % 1 === 0) {
    return roundedAmount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return roundedAmount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
};
