import "./styles.scss";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useDispatch } from "react-redux";
import { filterAvailabilityStatus } from "../../storage/filterMoreDetailsSlice";

const SelectBox = ({ groups, slice }) => {
  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedOptionsByGroup, setSelectedOptionsByGroup] = useState([]);

  const handleOptionClick = (option, groupName) => {
    setSelectedOptions(prevOptions => {
      if (prevOptions.includes(option)) {
        return prevOptions.filter(item => item !== option);
      } else {
        return [...prevOptions, option];
      }
    });
  
    setSelectedOptionsByGroup(prevOptions => {
      const groupIndex = prevOptions.findIndex(group => group.groupName === groupName);
  
      if (groupIndex !== -1) {
        const updatedGroup = {
          groupName,
          options: [...prevOptions[groupIndex].options],
        };
  
        const optionIndex = updatedGroup.options.indexOf(option);
        if (optionIndex !== -1) {
          updatedGroup.options.splice(optionIndex, 1);
          if (updatedGroup.options.length === 0) {
            return prevOptions.filter(group => group.groupName !== groupName); // Eliminar grupo si no hay opciones
          }
        } else {
          updatedGroup.options.push(option);
        }
  
        const newOptions = [...prevOptions];
        newOptions[groupIndex] = updatedGroup;
        return newOptions;
      } else {
        return [
          ...prevOptions,
          {
            groupName,
            options: [option],
          },
        ];
      }
    });
  };
  

  const handleClearFilters = () => {
    setSelectedOptions([]);
    setSelectedOptionsByGroup([]);
  };

  const getDisplayValue = () => {
    if (selectedOptions.length === 0) {
      return "Filters";
    } else {
      return selectedOptions.join(", ");
    }
  };

  const displayArrow = () => {
    if (isCollapsed) return <KeyboardArrowDownOutlinedIcon />;
    else return <KeyboardArrowUpOutlinedIcon />;
  };

  const toggleOptions = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if(slice === 'Availability')
    dispatch(filterAvailabilityStatus(selectedOptions))
    
  }, [selectedOptions, selectedOptionsByGroup])
  

  return (
    <div className="select-box">
      <div className="select-box__header" onClick={toggleOptions}>
        {selectedOptions.length > 0 ? (
          <div className="select-box__pills-container">
            <div className="select-box__pills">
            {selectedOptionsByGroup.map(group => (
              <>
                {group.options.map((option, index) => (
                  <div key={group.groupName + index} className="select-box__pill">
                  <div key={option} className="select-box__pill">
                    {option}
                    <button
                      className="select-box__pill-remove"
                      onClick={() => handleOptionClick(option, group.groupName)}
                    >
                      x
                    </button>
                  </div>
                  </div>
                ))}
              </>
            ))}
            </div>
            {displayArrow()}
          </div>
        ) : (
          <div className="select-box__header-text">
            {getDisplayValue()}
            {displayArrow()}
          </div>
        )}
      </div>
      <div className={`select-box__options ${isCollapsed ? "hidden" : ""}`}>
        <div className="select-box__option clear" onClick={handleClearFilters}>
          Clear Filters
        </div>
        {groups.map((group) => (
          <div key={group.name} className="select-box__group">
            <div className="select-box__group-name">{group.name}</div>
            {group.options.map((option) => (
              <div
                key={option}
                className={`select-box__option ${
                  selectedOptions.includes(option) ? "selected" : ""
                }`}
                onClick={() => handleOptionClick(option, group.name)}
              >
                {option}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectBox;
