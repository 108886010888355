import React, {useEffect, useRef, useState} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import { getNotes } from "../../../../api/api";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";

const NetIncomeT = ({ data }) => {

  const vwToPx = (vw) => (vw * width) / 100;
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const columnNames = [
    {name: "Property", type:'singleSelect', width:vwToPx(39)},
    {name: "Actual Net Income", type:'number', width:vwToPx(15), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "Budget Net Income", type:'number', width:vwToPx(15), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "$ Variance", type:'number', width:vwToPx(15), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "% Variance", type:'number', width:vwToPx(15), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
  ];

  const thresHoldValues = {
    percentVar:{
      thresHoldValue:'PercentVariance'
  }, 
}

  const height = useTableHeight(data);
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "NetIncome"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const {getThresholdData} = useThreshold({view:'budget', table: 'NetIncome', loader})

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
    "Net Income"
  );

  let columns = columenGen();

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="NetIncome"
          />
        </div>
      )}
       <DataGridPremium
  className="grid-style"
  rows={data}
  columns={columns}
  pinnedColumns={{left: ['name']}}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  getCellClassName={(params) => {
    return `${thresHolds(params, getThresholdData,thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  onCellClick={(cell, event) => {
    handleNote(
      cell.row.propertyID,
      cell.colDef.colNum,
      cell.formattedValue
    )(event);
  }}
  disableVirtualization
  hideFooter
  disableColumnSelector
  initialState={{
    aggregation: {
      model: {
    actualInc: 'sum',
    budgeInc: 'sum',
    moneyVar: 'sum',
    percentVar: 'avg',
      },
    },
  }}
/>
    </div>
  );
};

export default NetIncomeT;

// Legacy code
// import React from "react";
// import { money, percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import { getNotes } from "../../../../api/api";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const NetIncomeT = ({ data }) => {
//   const columnNames = [
//     "Property",
//     "Actual Net Income",
//     "Budget Net Income",
//     "$ Variance",
//     "% Variance",
//   ];
//   const height = useTableHeight(data);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "NetIncome"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'budget', table: 'NetIncome', loader})

//   const totalcurrent = data.reduce((acc, curr) => acc + curr?.actualInc, 0);
//   const totaloldest = data.reduce((acc, curr) => acc + curr?.budgeInc, 0);
//   const totalopen = data.reduce((acc, curr) => acc + curr?.moneyVar, 0);
//   // const totalopenMore = data.reduce((acc, curr) => acc + curr?.percentVar, 0);
//   const totalopenMore = (totalopen / totaloldest)

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="NetIncome"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               {columnNames.map((col, index) => {
//                 return <th key={index}>{col}</th>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={item?.propertyID}>
//                 <td onClick={handleNote(item?.propertyID, 0)} className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}>{item?.name}</td>
//                 <td onClick={handleNote(item?.propertyID, 1, item?.actualInc, columnNames)} className={handleCheckFlag(item?.propertyID, 1)} style={{textAlign:'right'}}>{money(item?.actualInc)}</td>
//                 <td onClick={handleNote(item?.propertyID, 2, item?.budgeInc, columnNames)} className={handleCheckFlag(item?.propertyID, 2)} style={{textAlign:'right'}}>{money(item?.budgeInc)}</td>
//                 <td onClick={handleNote(item?.propertyID, 3, item?.moneyVar, columnNames)} className={handleCheckFlag(item?.propertyID, 3)} style={{textAlign:'right'}}>{money(item?.moneyVar)}</td>
//                 <td onClick={handleNote(item?.propertyID, 4, item?.percentVar, columnNames)} className={`${getThresholdData(parseInt(item?.percentVar), 'PercentVariance')} ${handleCheckFlag(item?.propertyID, 4)}`}>{percentage(item?.percentVar)}%</td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td style={{textAlign:'right'}}>{money(totalcurrent)}</td>
//               <td style={{textAlign:'right'}}>{money(totaloldest)}</td>
//               <td style={{textAlign:'right'}}>{money(totalopen)}</td>
//               <td>{percentage(totalopenMore)}%</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default NetIncomeT;
