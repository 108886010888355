import React from "react";
import SelectColor from "../components/select";

const MaintenanceTab = () => {
  return (
    <>
      <SelectColor question="How many days should it take to complete a work order?" />
      <SelectColor question="How many days should it take to complete an awaiting parts work order?" />
      <SelectColor question="How many days should it take to turn an apartment?" />
    </>
  );
};

export default MaintenanceTab;
