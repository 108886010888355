import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import { noteStatus } from "../../storage/noteSlice";
import { useLocation } from "react-router-dom";
import { addNote } from "../../api/api";
import { setResetResponses } from "../../storage/filterSlice";

const NewNote = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const show = useSelector((state) => state.note.show);
  const tableName = useSelector((state) => state.note.currentTable);
  const columns = useSelector((state) => state.note.columns);
  const properties = useSelector((state) => state.filter.allProperties);
  const currentProperty = useSelector((state) => state.note.currentProperty);
  const currentColumn = useSelector((state)=> state.note.currentColumn);
  const currentColumnValue = useSelector((state)=> state.note.currentColumnValue);
  const bldgValue = useSelector((state)=> state.note.bldgValue);
  const accountValue = useSelector((state)=> state.note.accountValue);
  const sourceValue = useSelector((state)=> state.note.sourceValue);

  const [selectedProperty, setSelectedProperty] = useState(currentProperty.toString());
  const [selectedColumn, setSelectedColumn] = useState(currentColumn.toString());
  const [noteText, setNoteText] = useState("");
  const [expiration, setExpiration] = useState("30");
  const [table, setTable] = useState("");
  const [route, setRoute] = useState("");

  const handleShowModal = () => {
    dispatch(noteStatus("closed"));
    setNoteText("")
    setExpiration("30")
  
    dispatch(setResetResponses())
  };

  const handleEnterNote = async () => {
    if (noteText.trim() === "") {
      alert("Please enter a note text.");
      return;
    }

    if (
      selectedProperty === "0" ||
      selectedColumn === "0" ||
      expiration === "0"
    ) {
      alert("Please choose a property, column, and note expiration.");
      return;
    }

    // datos
    let req = {
      propertyID: selectedProperty,
      table: tableName,
      column: selectedColumn,
      note: noteText,
      duration: expiration,
      value:currentColumnValue,
      bldg: bldgValue ? bldgValue : undefined,
      account: accountValue ? accountValue : undefined,
      source: sourceValue ? sourceValue : undefined
    };

    try {
      addNote(req).then((res) => {
        handleShowModal();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const addSpace = (str) => {
    let count = 0;
    let result = "";

    for (let i = 0; i < str.length; i++) {
      if (str[i] === str[i].toUpperCase()) {
        count++;
        if (count === 2) {
          result += " ";
        }
      }
      result += str[i];
    }
    setTable(result);
  };

  const capitalizeFirstLetter = (str) => {
    const trimmedString = str.substring(1);

    const capitalizedString =
      trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1);

    setRoute(capitalizedString);
  };

  useEffect(() => {
    addSpace(tableName);
  }, [tableName]);

  useEffect(() => {
    capitalizeFirstLetter(location.pathname);
  }, [location]);

  useEffect(() => {
    setSelectedProperty(currentProperty.toString())
  },[currentProperty])

  useEffect(() => {
    setSelectedColumn(currentColumn.toString())
  },[currentColumn])

  return (
    <div className={`newNote-container ${show}`}>
      <h1>New Note</h1>
      <div className="line"></div>
      <div className="breadcumb">
        {route} {" > "} {table}
      </div>
      <div className="selects-double">
        <select
          value={selectedProperty}
          onChange={(e) => setSelectedProperty(e.target.value)}
        >
          <option value="0">Choose Property</option>
          {properties.map((property) => {
            return (
              <option key={property.propertyID} value={property.propertyID}>
                {property.propertyName}
              </option>
            );
          })}
        </select>
        <select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
        >
          <option value="0">Choose Column</option>
          {columns.map((column, index) => {
            return (
              <option key={column} value={index}>
                {column}
              </option>
            );
          })}
        </select>
      </div>
      <textarea
        placeholder="Type Note Here"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      ></textarea>
      <select
        style={{ width: "100%" }}
        value={expiration}
        onChange={(e) => setExpiration(e.target.value)}
      >
        <option value="0">Note Expiration (Choose)</option>
        <option value="30">30 Days</option>
        <option value="60">60 Days</option>
        <option value="90">90 Days</option>
      </select>
      <div className="buttons-double">
        <button className="gray" onClick={() => handleShowModal()}>
          CANCEL
        </button>
        <button className="blue" onClick={() => handleEnterNote()}>
          ENTER NOTE
        </button>
      </div>
    </div>
  );
};

export default NewNote;
