import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarExportContainer, GridCsvExportMenuItem } from '@mui/x-data-grid';

export default function ExportToolbar() {
    return (
      <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <GridToolbarFilterButton />
      <GridToolbarExportContainer>
      <GridCsvExportMenuItem />
    </GridToolbarExportContainer>
    </GridToolbarContainer>
    );
  }