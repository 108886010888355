// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Guminert Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Guminert Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Guminert Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Guminert Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html, body{
    height: 100%;
}

@font-face {
  font-family: "Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}

@font-face {
  font-family: "Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;EACtB,mCAAmC;EACnC,kCAAkC;EAClC,YAAY;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;EACE,sBAAsB;EACtB,+DAA0D;AAC5D;;AAEA;EACE,qBAAqB;EACrB,+DAAyD;AAC3D;;AAEA;EACE,mBAAmB;EACnB,+DAAuD;AACzD;;AAEA;EACE,oBAAoB;EACpB,+DAAwD;AAC1D","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Regular\";\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  height: 100%;\n}\n\nhtml, body{\n    height: 100%;\n}\n\n@font-face {\n  font-family: \"Regular\";\n  src: url(./fonts/Guminert\\ Regular.ttf) format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Medium\";\n  src: url(./fonts/Guminert\\ Medium.ttf) format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Bold\";\n  src: url(./fonts/Guminert\\ Bold.ttf) format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Light\";\n  src: url(./fonts/Guminert\\ Light.ttf) format(\"truetype\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
