const GET_COMPANY_ENV = () => {

    if(!process.env.REACT_APP_MAIN_COMPANY_ID)
        throw 'process.env.REACT_APP_MAIN_COMPANY_ID returns unknown value';

    if(!process.env.REACT_APP_COMPANY_DOMAIN_NAME)
        throw 'process.env.COMPANY_DOMAIN_NAME returns unknown value';

    return {
        companyId: process.env.REACT_APP_MAIN_COMPANY_ID,
        companyDomainName: process.env.REACT_APP_COMPANY_DOMAIN_NAME
    }
    
}

export default GET_COMPANY_ENV;