import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    value: 'open',
  },
  reducers: {
    sidebarStatus: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { sidebarStatus } = sidebarSlice.actions

export default sidebarSlice.reducer