import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
import LeasingTab from "./views/leasing";
import OccupancyTab from "./views/occupancy";
import MaintenanceTab from "./views/maintenance";
import CollectionsTab from "./views/collections";
import BudgetTab from "./views/budget";
import { useNavigate } from "react-router-dom";

const Thresholds = () => {
  const navigate = useNavigate();

  const status = useSelector((state) => state.sidebar.value);
  const [tab, setTab] = useState("Leasing");

  const showTab = () => {
    if (tab === "Leasing") return <LeasingTab></LeasingTab>;
    if (tab === "Occupancy") return <OccupancyTab></OccupancyTab>;
    if (tab === "Maintenance") return <MaintenanceTab></MaintenanceTab>;
    if (tab === "Collections") return <CollectionsTab></CollectionsTab>;
    if (tab === "Budget") return <BudgetTab></BudgetTab>;
  };

  const handleRedirectAdminButton = () => navigate("/admin");

  return (
    <>
      <div id="main">
        <div className="header">
          <h3
            className={`
                      title
                      ${"left-" + status}
                  `}
          >
            Operations Thresholds
          </h3>
        </div>
        <div className={`content ${status}`}>
          <div className="thresholds-container">
            <div className="top">
              <div
                className={`${tab === "Leasing" ? "tab active" : "tab"}`}
                onClick={() => setTab("Leasing")}
              >
                Leasing
              </div>
              <div
                className={`${tab === "Occupancy" ? "tab active" : "tab"}`}
                onClick={() => setTab("Occupancy")}
              >
                Occupancy
              </div>
              <div
                className={`${tab === "Maintenance" ? "tab active" : "tab"}`}
                onClick={() => setTab("Maintenance")}
              >
                Maintenance
              </div>
              <div
                className={`${tab === "Collections" ? "tab active" : "tab"}`}
                onClick={() => setTab("Collections")}
              >
                Collections
              </div>
              <div
                className={`${tab === "Budget" ? "tab active" : "tab"}`}
                onClick={() => setTab("Budget")}
              >
                Budget
              </div>
            </div>
            <div className="contentTab">
              {showTab()}
              <div className="bottom-buttons">
                <button className="gray" onClick={handleRedirectAdminButton}>
                  CANCEL
                </button>
                <button className="blue" onClick={handleRedirectAdminButton}>
                  SAVE CHANGES
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thresholds;
