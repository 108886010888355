import { useState, useEffect } from "react";
import {
  returnFilterResponse,
  returnHandleData,
} from "../functions/filterArray";
import { useSelector } from "react-redux";

const useFilterOverview = (apiFunction, object) => {
  const filterArray = useSelector((state) => state.filter.tableFilter);

  const [aux, setAux] = useState([]);
  const [loader, setLoader] = useState(true);
  const [sumParam, setSumParam] = useState(0);
  const [sumParam2, setSumParam2] = useState(0);
  const [sumParam3, setSumParam3] = useState(0);

  const fetchData = async () => {
    try {
      const fetchedData = await apiFunction();
      const res = returnHandleData(fetchedData[object], filterArray);
      setAux(fetchedData[object]);
      totalSum(res);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const totalSum = (res) => {
    if (object === "applicationOverView") {
      const sum1 = res?.reduce((acc, curr) => acc + curr?.applications?.MTD, 0);
      const sum2 = res?.reduce(
        (acc, curr) => acc + curr?.applications?.Goals,
        0
      );
      setSumParam(sum1);
      setSumParam2(sum2);
    }
    if (object === "currentWorkOrderOverview") {
      const sum1 = res?.reduce((acc, curr) => acc + curr?.current, 0);
      const sum2 = res?.reduce((acc, curr) => acc + curr?.open, 0);
      const sum3 = res?.reduce((acc, curr) => acc + curr?.lastCurrent, 0);
      setSumParam(sum1);
      setSumParam2(sum2);
      setSumParam3(sum3);
    }
    if (object === "currentDelinquencyOverView") {
      const sum1 = res?.reduce((acc, curr) => acc + curr?.money, 0);
      const sum2 = res?.reduce((acc, curr) => {
        // Check if curr and curr.amountPaid are not undefined
        if (curr && typeof curr.amountPaid !== "undefined") {
          return acc + curr.amountPaid;
        } else {
          return acc;
        }
      }, 0);
      const sum3 = res?.reduce((acc, curr) => acc + curr?.lastCurrent, 0);
      setSumParam3(sum3);
      setSumParam(sum1);
      setSumParam2(sum2);
    }
    if (object === "scheduleMovInMovOut") {
      const sum1 = res?.reduce((acc, curr) => acc + curr?.moveIns, 0);
      const sum2 = res?.reduce((acc, curr) => acc + curr?.moveOuts, 0);
      setSumParam(sum1);
      setSumParam2(sum2);
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, []);

  useEffect(() => {
    setLoader(true);
    const filtered = returnFilterResponse(aux, filterArray);
    totalSum(filtered);
    setLoader(false);
  }, [filterArray]);

  return { loader, sumParam, sumParam2, sumParam3 };
};

export default useFilterOverview;
