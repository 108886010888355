import React,{useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import { noteStatus, allNotesStatus, setShowPreviewNotes } from "../../storage/noteSlice";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import Draggable from 'react-draggable';

const ShowNotes = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.note.showAllNotes);
  const notes = useSelector((state) => state.note.listAllNotes);
  const columns = useSelector((state) => state.note.columns);

  const handleShowModal = () => {
    dispatch(noteStatus("open"));
    dispatch(allNotesStatus("closed"));
    dispatch(setShowPreviewNotes(false));
  };

  const closeAllNotes = () => {
    dispatch(allNotesStatus("closed"));
    dispatch(setShowPreviewNotes(false));
  }

  const vwToPx = (vw) => (vw * window.innerWidth) / 100;
  const vhToPx = (vh) => (vh * window.innerHeight) / 100;

let [currentPostion,setCurrentPostion] = useState({x: vwToPx(75), y: vhToPx(10)})

function onDrag(e,data){
  setCurrentPostion({x: data.x, y: data.y})
}

  return (
    <Draggable onDrag={onDrag} position={currentPostion}>
    <div className={`showNotes-container ${filter}`}>
      <div>
        <CancelTwoToneIcon
          fontSize="large"
          onClick={closeAllNotes}
          className="btn"
        />
      </div>
      <div className="buttons-double">
        <h3>{notes?.length} Notes</h3>
        <div className="addbtn" onClick={() => handleShowModal()}>
          Add Note <NoteAddIcon sx={{ color: "#0199AD" }} />
        </div>
      </div>
      {<div>
        <h4 style={{textAlign:'center', marginTop:'2%'}}>{`${columns[notes[0]?.column]}`}</h4>
      {notes?.map((note, index) => {
        return <ListNotes key={index} data={note}/>;
      })}
      </div>}
    </div>
    </Draggable>
  );
};

export default ShowNotes;

const ListNotes = ({ data }) => {

  const getHour = (time) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const date = new Date(time).toLocaleString("en-US", options);

    return date;
  };

  const getDate = (time) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };

    const date = new Date(time).toLocaleDateString("en-US", options);

    return date;
  };

  const getOvertime = (date, days) => {
    const futureDate = new Date(date);
    futureDate.setDate(futureDate.getDate() + days);

    const currentDate = new Date();

    const timeRemaining = Math.ceil(
      (futureDate - currentDate) / (1000 * 60 * 60 * 24)
    );

    return timeRemaining;
  };

  let [showReadMe,setShowReadMe] = useState(false)

  return (
    <div className="listShowNotes">
      <div className="buttons-double blue">
        <h4>{data.propertyName}</h4>
        {/* <h2>{data.propertyID}</h2> */}
      </div>

      <div className="buttons-double yellow">
        <h4>{getDate(data.createdAt)}</h4>
        <h4>{getHour(data.createdAt)}</h4>
        <h4>{data.value}</h4>
      </div>
      <div className="buttons-double gray">
        <h3>{data.user}</h3>
        {/* <h2>{data.userTitle}</h2> */}
      </div>
      {/* <div className="overtime">
        Open {getOvertime(data.createdAt, data.duration)} Days
      </div> */}
      <div className="buttons-double green">
        {!showReadMe && data.note.length > 30 ?
        <div>
          <h4>{data.note.slice(0,25)}...</h4>
          <button onClick={()=>{setShowReadMe(prevState => !prevState)}}>Read More</button>
        </div> : <h4>{data.note}</h4>
        }
        </div>
    </div>
  );
};
