export const filterResponse = (auxArray, filterArray, setData) => {
  let filteredResponse = auxArray.filter((responseObj) =>
    filterArray.some(
      (filterObj) => filterObj.propertyID === responseObj.propertyID
    )
  );
  setData(filteredResponse)
};

export const handleData = (data, setData, filterArray, setLoader, setAux) => {
  setLoader(true);
  if (filterArray.length > 0) {
    filterResponse(data, filterArray, setData);
  } else {
    setData(data);
  }
  setAux(data)
  setLoader(false);
};

export const returnFilterResponse = (auxArray, filterArray) => {
  let filteredResponse = auxArray.filter((responseObj) =>
    filterArray.some(
      (filterObj) => filterObj.propertyID === responseObj.propertyID
    )
  );
  return filteredResponse
};

export const returnHandleData = (data, filterArray) => {
  if (filterArray.length > 0) {
    return returnFilterResponse(data, filterArray);
  } else {
    return data;
  }
}

export const filterResponseMoreData = (auxArray, filterArray1, filterArray2, property, setData) => {
  let filteredResponse;
  if (filterArray1.length > 0 && filterArray2.length > 0){
  filteredResponse = auxArray.filter((responseObj) =>
  filterArray1.some(
      (filterObj) => filterObj.propertyID === responseObj.propertyID
    ) &&
    filterArray2.some(
      (filterObj) => filterObj === responseObj[property]
    )
  );
  }else if(filterArray1.length > 0){
    filteredResponse = returnFilterResponse(auxArray, filterArray1, setData);
  } else if(filterArray2.length > 0){
    console.log(property)
    filteredResponse = auxArray.filter((responseObj) =>
    filterArray2.some(
      (filterObj) => filterObj === responseObj[property]
    )
  );
  }else{
    filteredResponse = auxArray
  }


  setData(filteredResponse)
};