import { React } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
  ScriptableContext,
  Legend,
  Tooltip,
} from "chart.js";

Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Filler);
Chart.register(Legend);
Chart.register(Tooltip);

const LineChartTD = ({dataChart, labels=[]}) => {
  const colorPalette = [
    "rgba(250,174,50,1)",
    "rgba(75,192,192,1)",
    "rgba(255,99,132,1)",
    "rgba(54,162,235,1)",
    "rgba(255,206,86,1)",
    "rgba(153,102,255,1)",
  ];
  const colorPaletteGradient = [
    "rgba(250,174,50,0)",
    "rgba(75,192,192,0)",
    "rgba(255,99,132,0)",
    "rgba(54,162,235,0)",
    "rgba(255,206,86,0)",
    "rgba(153,102,255,0)",
  ];
  
  
  const dataObjects = dataChart.map((item, index) => {
    const colorIndex = index >= 6 ? index % 6 : index;
    const borderColor = colorPalette[colorIndex];
    const gradientColor = colorPaletteGradient[colorIndex];
    const backgroundColor = (context) => {
      const ctx = context.chart.ctx;
      const heightChart = ctx.canvas.height - ctx.canvas.height * 0.1;
      const gradient = ctx.createLinearGradient(0, 0, 0, heightChart);
      gradient.addColorStop(0, borderColor);
      gradient.addColorStop(1, gradientColor);
      return gradient;
    };
  
    return {
      label: item.label,
      data: item.data,
      borderColor,
      backgroundColor,
      borderWidth: 2,
      fill: true,
      lineTension: 0,
      pointStyle: 'circle',
      pointRadius: 3,
      pointHoverRadius: 15,
    };
  });
  

  const data = {
    labels: labels,
    datasets: dataObjects
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        display: true, // Set to true to display the legend
        labels: {
          font: {
            size: 12, // You can customize the font size of the legend labels
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Day of the Month",
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Delinquency Amt",
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  return (
    <div style={{ maxWidth: "70vw", height: "300px", display: "flex" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChartTD;
