import React from "react";
import SelectColor from "../components/select";

const BudgetTab = () => {
  return (
    <>
      <SelectColor question="How much variance over budget is acceptable?" />
      <SelectColor question="How much variance under budget is acceptable?" />
    </>
  );
};

export default BudgetTab;
