import React from "react";
import useFilter from "../../hooks/useFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSelector } from "react-redux";

const FilterButton = ({ hasTitle = false, title = "" }) => {
  const status = useSelector((state) => state.sidebar.value);
  const { handleToggleFilter } = useFilter();

  const buttonReturn = () => {
    if (hasTitle) {
      return (
        <div className="hasbutton">
          <h2>{title}</h2>
          <button
            onClick={handleToggleFilter}
            className="blue"
            style={{ marginRight: 0, marginLeft: "auto" }}
          >
            PROPERTIES FILTERS <FilterAltIcon sx={{ fontSize: "12px" }} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="header">
          <h3
            className={`
                    title
                    ${"left-" + status}
                `}
          >
            <button
              onClick={handleToggleFilter}
              className="filter-button"
              style={{ marginRight: 0, marginLeft: "auto" }}
            >
              PROPERTIES FILTERS <FilterAltIcon sx={{ fontSize: "12px" }} />
            </button>
          </h3>
        </div>
      );
    }
  };

  return buttonReturn();
};

export default FilterButton;
