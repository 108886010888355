import axios from "axios";
import GET_ENV from "../utils/getEnv";
import GET_API_URLS_ENV from "../utils/getApiUrls";

//default url
var apiUrl = "https://api.thepropertygauge.com/api/v1";
var urls = GET_API_URLS_ENV();

if(GET_ENV() === 'DEV'){
  apiUrl = `${urls.mainApiUriDev}/api/v1`
}else if (GET_ENV() === 'PROD'){
  apiUrl = `${urls.mainApiUri}/api/v1`
}else if (GET_ENV() === 'DEMO'){
  apiUrl = `${urls.mainApiUriDemo}/api/v1`
}
const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("@property-token");
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    const properties = window.localStorage.getItem("@properties-filter");
    if (properties) {
      config.headers.properties = properties;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getUsers = async () => {
  const response = await instance.get(`${apiUrl}/users`);
  return response.data;
};

export const getUserById = async (id) => {
  const response = await instance.get(`${apiUrl}/users/${id}`);
  return response.data;
};

export const deleteUserById = async (id) => {
  const response = await instance.delete(`${apiUrl}/users/${id}`);
  return response.data;
};

export const updateUser = async (id, user) => {
  const response = await instance.put(`${apiUrl}/users/${id}`, user);
  return response.data;
};

export const createUser = async (user) => {
  const response = await instance.post(`${apiUrl}/users/add`, user);
  return response.data;
};

export const createUserAdmin = async (user) => {
  const response = await instance.post(
    `${apiUrl}/auth/admin/create-user`,
    user
  );
  return response.data;
};

export const getCompanies = async () => {
  const response = await instance.get(`${apiUrl}/companies`);
  return response.data;
};

export const getProperties = async () => {
  const response = await instance.get(`${apiUrl}/properties`);
  return response.data;
};

export const getPropertiesGroups = async () => {
  const response = await instance.get(`${apiUrl}/property-groups`);
  return response.data;
};

export const postPropertiesGroups = async (data) => {
  const response = await instance.post(`${apiUrl}/property-groups`, data);
  return response.data;
};

export const getPropertyGroups = async (id) => {
  const response = await instance.get(`${apiUrl}/property-groups/${id}`);
  return response.data;
};

export const putPropertiesGroups = async (id, data) => {
  const response = await instance.put(`${apiUrl}/property-groups/${id}`, data);
  return response.data;
};

export const deletePropertiesGroups = async (id) => {
  const response = await instance.delete(`${apiUrl}/property-groups/${id}`);
  return response.data;
};

export const getCurrentOccupancy = async () => {
  const response = await instance.get(`${apiUrl}/current-occupancy`);
  return response.data;
};

export const generateSchedules = async () => {
  const response = await instance.get(`${apiUrl}/schedule`);
  return response.data;
};

export const generateSchedulesByDate = async (data) => {
  const response = await instance.post(`${apiUrl}/schedule`, data);
  return response.data;
};

export const generateRenewals = async () => {
  const response = await instance.get(`${apiUrl}/renewals`);
  return response.data;
};

export const generateRenewalsDetails = async (params) => {
  let page = params.page 
  let rows = params.rows
  const response = await instance.get(`${apiUrl}/renewals/details?page=${page}&rows=${rows}`);
  return response.data;
};

export const getCurrentsWorkOrders = async () => {
  const response = await instance.get(`${apiUrl}/work-orders/current`);
  return response.data;
};

export const getTrendWorkOrders = async () => {
  const response = await instance.get(`${apiUrl}/work-orders/trend`);
  return response.data;
};

export const getTurnOver = async () => {
  const response = await instance.get(`${apiUrl}/work-orders/turn-over`);
  return response.data;
};

export const getCurrentAvailability = async () => {
  const response = await instance.get(`${apiUrl}/leasing/current-available`);
  return response.data;
};

export const getRentalTimeline = async () => {
  const response = await instance.get(`${apiUrl}/leasing/rental-timeline`);
  return response.data;
};

export const getConversion = async () => {
  const response = await instance.get(`${apiUrl}/leasing/conversion`);
  return response.data;
};

export const getSuccess = async () => {
  const response = await instance.get(`${apiUrl}/leasing/success`);
  return response.data;
};

export const getLastUpdate = async () => {
  const response = await instance.get(`${apiUrl}/last-update`);
  return response.data;
};

export const getTrendingDelinquency = async () => {
  const response = await instance.get(
    `${apiUrl}/collections/trending-delinquency`
  );
  return response.data;
};

export const getAgedDelinquency = async () => {
  const response = await instance.get(`${apiUrl}/collections/aged-delinquency`);
  return response.data;
};

export const getCurrentDelinquency = async () => {
  const response = await instance.get(
    `${apiUrl}/collections/current-delinquency`
  );
  return response.data;
};

export const getExpense = async () => {
  const response = await instance.get(`${apiUrl}/budgets/expense-budget`);
  return response.data;
};

export const getIncomes = async () => {
  const response = await instance.get(`${apiUrl}/budgets/income-budget`);
  return response.data;
};

export const getNetIncomes = async () => {
  const response = await instance.get(`${apiUrl}/budgets/net-income`);
  return response.data;
};

export const getTotalNetIncomes = async () => {
  const response = await instance.get(`${apiUrl}/budgets/total-net-income`);
  return response.data;
};

export const getApplications = async () => {
  const response = await instance.get(`${apiUrl}/overview/applications`);
  return response.data;
};

export const getWorkOrders = async () => {
  const response = await instance.get(`${apiUrl}/overview/work-order`);
  return response.data;
};

export const getCurrentD = async () => {
  const response = await instance.get(`${apiUrl}/overview/current-delinquency`);
  return response.data;
};

export const getMoveInMoveOut = async () => {
  const response = await instance.get(`${apiUrl}/overview/move-in-move-out`);
  return response.data;
};

export const getNotes = async (table) => {
  const response = await instance.get(`${apiUrl}/notes/${table}`);
  return response.data;
};

export const addNote = async (data) => {
  const response = await instance.post(`${apiUrl}/notes`, data);
  return response.data;
};

export const getAvailability = async () => {
  const response = await instance.get(`${apiUrl}/leasing/current-available-details`);
  return response.data;
};

export const getLeadConversion = async (router) => {
  const response = await instance.get(`${apiUrl}/leasing/success-details?${router}`);
  return response.data;
};

export const getMaintenanceDetail = async () => {
  const response = await instance.get(`${apiUrl}/work-orders/current-details `);
  return response.data;
};

export const getCollectionsDetails = async () => {
  const response = await instance.get(`${apiUrl}/collections/current-delinquency-details`);
  return response.data;
};

export const getBudgetDetails = async (data) => {
  if(data?.page === undefined) {data.page=1; data.rows=25}
  const response = await instance.get(`${apiUrl}/budgets/details?page=${data.page}&rows=${data.rows}`);
  return response.data;
};

export const forgetPassword = async (email) => {
  try{
    const response = await instance.post(`${apiUrl}/forgotpassword/sendForgotPasswordEmail`,{
      email
    });
    return response.status
  }catch(e){
    return e
  }
};

export const validateTokenAndEmail = async (email,token) => {
  try{
    const response = await instance.post(`${apiUrl}/forgotpassword/verifyToken`,{
      email,
      token
    });
    return response
  }catch(e){
    return null
  }
};

export const updateForgetPassword = async (email,token, password) => {
  try{
    const response = await instance.post(`${apiUrl}/forgotpassword/updatePassword`,{
      email,
      token,
      password
    });
    return response
  }catch(e){
    return e.response.data
  }
};