import React,{useEffect, useState} from "react";
import "../styles.scss";
import { Link } from "react-router-dom";
import { deletePropertiesGroups } from "../../../../api/api";

const Groups = ({ data }) => {

let [groups,setGroups] = useState(data)

let groupsUpdater = (item)=>{
  deletePropertiesGroups(item)
  let filteredData = groups.filter((group)=>{
   return item !== group.id
  })
setGroups(filteredData)
}

  return (
    <div className="container-table">
      <div className="wrapper">
        <table>
          <tbody>
            {groups?.map((item, index) => (
              <tr key={index}>
                <td className={`${index % 2 === 0 ? 'white' : ''}`} style={{ color: "black" }}>
                  <strong>{item?.groupName}</strong>
                </td>
                <td style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <Link className="edit" to={`/editGroup/${item.id}`}>
                      EDIT
                    </Link>
                    <a className="edit" href='javascript:void(0)' style={{ marginLeft: "10px" }} onClick={()=>{groupsUpdater(item.id)}}>
                      Delete
                    </a>
                  </div>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Groups;
