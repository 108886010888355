import React from "react";
import "./styles.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch } from "react-redux";
import {
  noteStatus,
  allNotesStatus,
  setListAllNotes,
  setColumns,
  setTableName,
  setShowPreviewNotes,
} from "../../storage/noteSlice";

const Note = ({ notes = [], columnNames = [], table = "" }) => {
  const dispatch = useDispatch();
  const getHour = (time) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const date = new Date(time).toLocaleString("en-US", options);

    return date;
  };

  const getDate = (time) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };

    const date = new Date(time).toLocaleDateString("en-US", options);

    return date;
  };

  const handleListNotes = () => {
    if (notes.length > 0) {
      return (
        <>
          <div className="container-note-item">
            {notes.map((note, index) => {
              return (
                <ListOfNotes
                  key={index}
                  data={{
                    name: note.user,
                    date: getDate(note.createdAt),
                    message: note.note,
                    time: getHour(note.createdAt),
                    value: note.value
                  }}
                />
              );
            })}
          </div>
          <button className="blue" onClick={() => handleShowAllListNotes()}>
            SEE FULL NOTE(S)
          </button>
        </>
      );
    } else {
      return (
        <div className="bottom">
          <button className="blue" onClick={() => handleShowAddNote()}>
            ADD NOTE
            <AddCircleOutlineIcon sx={{ fontSize: "12px" }} />
          </button>
        </div>
      );
    }
  };

  const handleShowAllListNotes = () => {
    dispatch(allNotesStatus("open"));
    dispatch(noteStatus("closed"));
    dispatch(setListAllNotes(notes));
    dispatch(setColumns(columnNames));
    dispatch(setTableName(table));
  };

  const handleShowAddNote = () => {
    dispatch(noteStatus("open"));
    dispatch(allNotesStatus("closed"));
    dispatch(setListAllNotes(notes));
    dispatch(setColumns(columnNames));
    dispatch(setTableName(table));
  };

  const closePreviewNotes = () => {
    dispatch(setShowPreviewNotes(false));
  }

  return (
    <div className="note-container">
      <div className="top"><div></div><CloseOutlinedIcon className="iconAction" onClick={() => closePreviewNotes()}/></div>
      <div className="top">
        <p>{notes.length} Notes</p>
        {notes.length > 0 ? (
          <button className="addNote-btn" onClick={() => handleShowAddNote()}>
            Add Note <AddCircleOutlineIcon sx={{ fontSize: "12px" }} />
          </button>
        ) : (
          <></>
        )}
      </div>
      {handleListNotes()}
    </div>
  );
};

export default Note;

const ListOfNotes = ({ data }) => {
  return (
    <div className="note-item">
      <div className="left">
        <h3>{data.name}</h3>
        <h4>{data.date}</h4>
        <h5>{data.message}</h5>
        <h6>{data.value}</h6>
      </div>
      <div className="right">{data.time}</div>
    </div>
  );
};
