import React from "react";
import SelectColor from "../components/select";

const CollectionsTab = () => {
  return (
    <>
      <div className="top-selector">
        Consider residents deliquent if they owe more than:{" "}
        <button>$500</button>
      </div>
      <SelectColor question="How many delinquent leases should have delinquency notes?" />
      <SelectColor question="How many days should go to between deliquency notes?" />
      <SelectColor question="How much of the rent do you expect to be collected by the last day?" />
    </>
  );
};

export default CollectionsTab;
