import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CollectionDetailTable from "../tables/collectionD";
import FilterButton from "../../../components/filterButton";
import CircularProgress from "@mui/material/CircularProgress";
import useFilterResponse from "../../../hooks/useFilterResponse";
import { getCollectionsDetails } from "../../../api/api";

export default function CurrentDM() {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  const { response, loader } = useFilterResponse(getCollectionsDetails, "currenDelinquencyDetails");

  const handleRedirectMaintenanceButton = () => navigate("/collections");

  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className="maintenance-container">
          <div className={`child ${status}`}>
            <FilterButton hasTitle={true} title="Delinquencies" />
            <div className="line"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <button onClick={handleRedirectMaintenanceButton}>
                {"<"} BACK
              </button>
              {/* <select></select> */}
            </div>
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <CollectionDetailTable data={response} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
