import React, { useEffect, useState } from "react";
import "../styles.scss";

const Properties = ({ data }) => {

  const [height, setHeight] = useState("430px");

  useEffect(() => {
    if (data?.length <= 10) {
      let len = (data?.length ) * 43;
      setHeight(len.toString() + "px");
    }
  }, [data]);

  return (
    <>
    <div className="container-table" style={{ height: height }}>
      <div className="wrapper">
        <table>
          <thead>
            <tr>
              <th>Property</th>
              <th>Location</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className={`${index%2 === 0 ? 'white' : ''}`}>{item?.propertyName}</td>
                <td>{item?.city}, {item?.state}</td>
                <td>{item?.propertyType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
          <a style={{float:'right',padding:'5px'}}><u><i> Please contact your account representative to add a new property </i></u></a>
          </>
  );
};

export default Properties;
