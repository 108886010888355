import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, LinearScale, PointElement, LineElement, Filler, Legend, Tooltip } from "chart.js";

Chart.register(LinearScale, PointElement, LineElement, Filler, Legend, Tooltip);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const LineChatEVB = ({ income = [], budgetedIncome = [], expenses = [],  budgetedExpense = [], netIncome = [], budgetedNetIncome = [] }) => {
  // const currentDate = new Date();
  // const months = Array.from({ length: 12 }, (_, i) => {
  //   const month = currentDate.getMonth() - i;
  //   return monthNames[(month + 12) % 12];
  // }).reverse();

  const createGradient = (ctx, color) => {
    const heightChart = ctx.canvas.height - ctx.canvas.height * 0.1;
    const gradient = ctx.createLinearGradient(0, 0, 0, heightChart);
    gradient.addColorStop(0, color);
    gradient.addColorStop(1, "rgba(0,0,0,0)");
    return gradient;
  };

  const datasets = [
    {
      label: "Income",
      data: income,
      borderColor: "rgba(250,174,50,1)",
      backgroundColor: (context) => createGradient(context.chart.ctx, "rgba(250,174,50,0)"),
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 15,
      borderWidth: 3,
      fill: true,
      lineTension: 0,
    },
    {
      label: "Budgeted Income",
      data: budgetedIncome,
      borderColor: "rgba(250,174,50,.25)",
      backgroundColor: (context) => createGradient(context.chart.ctx, "rgba(250,174,50,.25)"),
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 15,
      borderWidth: 3,
      fill: true,
      lineTension: 0,
    },
    {
      label: "Expenses",
      data: expenses,
      borderColor: "rgba(0,0,0,1)",
      backgroundColor: (context) => createGradient(context.chart.ctx, "rgba(0,0,0,0)"),
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 15,
      borderWidth: 3,
      fill: true,
      lineTension: 0,
    },
    {
      label: "Budgeted Expenses",
      data: budgetedExpense,
      borderColor: "rgba(0,0,0,.25)",
      backgroundColor: (context) => createGradient(context.chart.ctx, "rgba(0,0,0,.25)"),
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 15,
      borderWidth: 3,
      fill: true,
      lineTension: 0,
    },
    {
      label: "Net Income",
      data: netIncome,
      borderColor: "rgba(75,192,192,1)",
      backgroundColor: (context) => createGradient(context.chart.ctx, "rgba(75,192,192,0)"),
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 15,
      borderWidth: 3,
      fill: true,
      lineTension: 0,
    },
    {
      label: "Budgeted Net Income",
      data: budgetedNetIncome,
      borderColor: "rgba(75,192,192,.25)",
      backgroundColor: (context) => createGradient(context.chart.ctx, "rgba(75,192,192,.25)"),
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 15,
      borderWidth: 3,
      fill: true,
      lineTension: 0,
    },
  ];

  const chartData = {
    labels: monthNames,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        display: true,
        labels: {
          font: {
            size: 12,
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Dollars ($)",
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  return (
    <div style={{ maxWidth: "70vw", height: "300px", display: "flex" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineChatEVB;
