import React, { useEffect, useState } from "react";
import "../../styles.scss";
import logo from "../../assets/Original Logo.svg";
import "./login.scss";
import { useDispatch } from "react-redux";
import {
  authStatus,
  setGroupsProperty,
  setPropertiesUser,
  setToken,
} from "../../storage/authSlice";
import { redirect,useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { adminLogin } from "../../api/authUtils";
import { resetFilters } from "../../storage/filterSlice";
import { forgetPassword } from "../../api/api";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate()
  //secret.johnson@thepropertygauge.com
  //john01
  const [forgot, setForgot] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [messageSeverity, setMessageSeverity] = useState('')


  const sendInfo = (e) => {
    // e.preventDefault();
    const fetchLogin = async () => {
      try {
        const fetchedLogin = await adminLogin(userEmail, userPassword);
        if (fetchedLogin?.data?.user?.hasAccess) {
          setHasError(false);
          window.localStorage.setItem(
            "@property-token",
            fetchedLogin?.data?.token
          );
          const userData = fetchedLogin?.data?.user;
          console.log(fetchedLogin?.data);
          const updatedStore = { userData, auth: true };
          window.localStorage.setItem(
            "@property-userData",
            JSON.stringify(updatedStore)
          );
          dispatch(setPropertiesUser(userData.properties));
          dispatch(setGroupsProperty(userData.propertyGroups));
          dispatch(setToken(userData.id));
          dispatch(authStatus(true));
          dispatch(resetFilters());
          redirect("/overview");
        } else {
          console.log("error");
          setMessageSeverity('error')
          setErrorMessage('The email or password is incorrect')
          setHasError(true);
        }
      } catch (error) {
        console.log(error);
        setHasError(true);
      }
    };
    fetchLogin();
  };

let forgetPasswordHandler = async ()=>{

let response = await forgetPassword(userEmail)

if(response === 200){
  setHasError(true)
  setMessageSeverity('success')
  setErrorMessage('Password reset Link has been sent!')
  return
}

setHasError(true)
setMessageSeverity('error')
setErrorMessage(response.response?.data?.msg)
}
  
  const onChangeUserMail = (e) => setUserEmail(e.target.value);
  const onChangePassword = (e) => setUserPassword(e.target.value);

  function pressEnter(e){
    if(e.key === 'Enter') return sendInfo()
  return null
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setHasError(false);
  };

  return (
    <>
      <div className="container-Login" onKeyDown={pressEnter} tabIndex="0">
        <Snackbar
          autoHideDuration={3000}
          onClose={handleClose}
          open={hasError}
          sx={{ height: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {/* <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}> */}
          <Alert onClose={handleClose} severity={messageSeverity} sx={{ width: "100%" }}>
            {/* The email or password is incorrect */}
            {errorMessage}
          </Alert>
        </Snackbar>
        <div className="card-Login">
          <img src={logo} />

          {forgot ? (
            <div className="form forgot">
              <div className="input-container">
                <label>Email</label>
                <input type="email" onChange={onChangeUserMail}></input>
              </div>
              <div className="buttons">
                <button className="back" onClick={() => setForgot(false)}>
                  BACK
                </button>
                <button onClick={forgetPasswordHandler}>RESET PASSWORD</button>
              </div>
            </div>
          ) : (
            <div className="form">
              <label>Email</label>
              <input onChange={onChangeUserMail} type="email"></input>
              <br />
              <label>Password</label>
              <input onChange={onChangePassword} type="password"></input>
              <a onClick={() => setForgot(true)}>Forgot Password?</a>
              <button onClick={sendInfo}>LOGIN</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
