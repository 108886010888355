import "../../styles.scss";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";

const Help = () => {
  const status = useSelector((state) => state.sidebar.value);
  let elementRef = useRef(false);

  useEffect(() => {
    const script = document.createElement("script");
    let scriptLoader = document.getElementById("scriptLoader");

    if (!elementRef.current && script && scriptLoader) {
      script.id = "fswebforms";
      script.src =
        "https://propertygaugellc.myfreshworks.com/crm/sales/web_forms/4daf25f9fd7489e6ac3ea24de3c35e3a985bc7e887b27dc781500e5e2cfb8a34/form.js";
      script.crossOrigin = "anonymous";
      scriptLoader.appendChild(script);
      elementRef.current = true;
    }

    return () => {
      const existingScript = document.getElementById("fswebforms");
      if (existingScript && !elementRef.current) {
        document.getElementById("scriptLoader").removeChild(existingScript);
      }
    };
  }, []);

  return (
    <>
      <div id="main">
        <div className={`content ${status}`}>
          <div className="settings-container">
            <div>
              <h2 style={{ textAlign: "center" }}>Help</h2>
              <div className="line"></div>
              <div id="scriptLoader">
                {/* <div className="content-mid"> */}
                {/* <div style={{ margin: "2rem 2rem 0 2rem" }}>
                  <h3 style={{ marginBottom: "1rem" }}>Contact Email</h3>
                  <div className="contact-email-form">
                    <label>Name</label>
                    <input type="text"></input>
                    <label>Subject Line</label>
                    <input type="text" placeholder="Enter subject line"></input>
                    <label>Message</label>
                    <br></br>
                    <textarea
                      rows={4}
                      cols={50}
                      placeholder="Enter your question/concern here"
                    ></textarea>
                    <div className="center-btn">
                      <button className="blue">SEND MESSAGE</button>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "2rem 2rem 0 2rem" }}>
                  <h3>Contact Phone Number</h3>
                  <div className="contact-phone">
                    <div className="phone">
                      <PhoneEnabledIcon sx={{ color: 'white' }} fontSize="large"/>
                    </div>
                    <label>+1(000) 000-0000</label>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
