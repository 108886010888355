import React, { useState, useEffect } from "react";
import LineChatEVB from "../../chart";

const SelectProperty = ({ data }) => {
  const [income, setIncome] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [netIncome, setNetIncome] = useState([]);
  const [budgetedIncome, setBudgetIncome] = useState([]);
  const [budgetedExpense, setBudgetExpense] = useState([]);
  const [budgetedNetIncome, setBudgetNetIncome] = useState([]);
  const [loader, setLoader] = useState(true);

  const getOperating = (data, type) => {
    const incomeData = data.map((property) => {
      const incomeResult = property.result.find(
        (result) => result?.name === type
      );
      return incomeResult ? incomeResult.data : [];
    });

    return incomeData
  };

  const generateTotalNet = (arrayOfArrays) => {
    const sumArray = arrayOfArrays?.reduce((acc, curr) => {
      curr.forEach((value, index) => {
        if (!acc[index]) {
          acc[index] = value;
        } else {
          acc[index] += value;
        }
      });
      return acc;
    }, []);

    return sumArray
  }

  useEffect(() => {
    if(data.length > 0){
      setLoader(true)
      const totalIncome = generateTotalNet(getOperating(data, "Income"))
      setIncome(totalIncome);

      const totalExpenses = generateTotalNet(getOperating(data, "Expenses"))
      setExpenses(totalExpenses);

      const totalNetIncome = generateTotalNet(getOperating(data, "Net Income"))
      setNetIncome(totalNetIncome);

      const totalBudgetIncome = generateTotalNet(getOperating(data, "Budgeted Income"))
      setBudgetIncome(totalBudgetIncome);

      const totalBudgetExpenses = generateTotalNet(getOperating(data, "Budgeted Expense"))
      setBudgetExpense(totalBudgetExpenses);

      const totalBudgetNetIncome = generateTotalNet(getOperating(data, "Budgeted Net Income"))
      setBudgetNetIncome(totalBudgetNetIncome);

      setLoader(false)
    }
  }, [data]);

  return (
    <div>
      {loader ? <></> : <LineChatEVB income={income} budgetedIncome={budgetedIncome} expenses={expenses}  budgetedExpense={budgetedExpense} netIncome={netIncome} budgetedNetIncome={budgetedNetIncome}/>}
    </div>
  );
};

export default SelectProperty;
