export const getFirstAndCurrentDayOfCurrentMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const currentDate = new Date(year, month, 1);
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const todayDate = new Date(today);
    firstDayOfMonth.setUTCHours(0, 0, 0, 0);
    today.setUTCHours(23, 59, 59, 0);
    return {firstDayOfMonth, todayDate}
}

export const getDefaultDates =() =>{
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    let defaultEndDate = new Date(year, month, day -1);
    let defaultStartDate = new Date(year, month, 1);
    if (defaultEndDate < defaultStartDate ){
      defaultStartDate = new Date(year, month -1, 1);;
    }
    // defaultStartDate.setUTCHours(0,0,0,0);
    // defaultEndDate.setUTCHours(23,59,59,0);
    console.log(defaultStartDate,defaultEndDate)
    return {defaultStartDate,defaultEndDate}
}