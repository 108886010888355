import { createSlice } from "@reduxjs/toolkit";

export const thresholdSlice = createSlice({
  name: "threshold",
  initialState: {
    leasing: {},
    occupancy: {},
    maintenance: {},
    collections: {},
    budget: {},
  },
  reducers: {
    saveLeasingData: (state, action) => {
      state.leasing = action.payload;
    },
    saveOccupancyData: (state, action) => {
      state.occupancy = action.payload;
    },
    saveMaintenanceData: (state, action) => {
      state.maintenance = action.payload;
    },
    saveCollectionsData: (state, action) => {
      state.collections = action.payload;
    },
    saveBudgetData: (state, action) => {
      state.budget = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    saveLeasingData,
    saveOccupancyData,
    saveMaintenanceData,
    saveCollectionsData,
    saveBudgetData,
} = thresholdSlice.actions;

export default thresholdSlice.reducer;
