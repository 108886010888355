import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({confirmButton, children}) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    confirmButton(false)
  };
  const handleConfirm = () => {
    setOpen(false);
    confirmButton(true)
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color='error'>
          {children}
        </DialogTitle>
        <DialogActions>
          <Button Button variant="outlined"  color='error' onClick={handleClose}>Cancel</Button>
          <Button Button variant="contained" color='success' onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}