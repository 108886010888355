import axios from "axios";
import GET_ENV from "../utils/getEnv";
import GET_API_URLS_ENV from "../utils/getApiUrls";

var apiUrl = "https://api.thepropertygauge.com/api/v1";
var urls = GET_API_URLS_ENV();

if(GET_ENV() === 'DEV'){
  apiUrl = `${urls.mainApiUriDev}/api/v1`
}else if (GET_ENV() === 'PROD'){
  apiUrl = `${urls.mainApiUri}/api/v1`
}
else if (GET_ENV() === 'DEMO'){
  apiUrl = `${urls.mainApiUriDemo}/api/v1`
}


const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("@property-token");
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const adminLogin = async (email, password) => {
  try {
    const response = await instance.post("auth/sign-in", {
      email: email,
      password: password,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async () => {
  try {
    const response = await instance.get("auth/user-data");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const refreshToken = async (refreshToken) => {
  try {
    const response = await instance.post("/auth/refresh-token", {
      refreshToken,
    });
    const { token, refreshToken: newRefreshToken } = response.data;
    // Actualiza el token y el refreshToken en tu aplicación
    // por ejemplo, guardándolos en el estado global
    return response;
  } catch (error) {
    // Manejar errores de solicitud, como un refreshToken inválido
    console.error(error);
  }
};
