import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sidebarStatus } from "../../storage/sidebarSlice";
import { authStatus, checkRole } from "../../storage/authSlice";
import logo from "../../assets/LogoWhite.svg";
import tab from "../../assets/tab.svg";
import { redirect } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AlertDialog from "../dialog/AlertDialog";
import GET_COMPANY_ENV from "../../utils/getCompanyEnv";

import "./sidebar.scss";
import { getUserById } from "../../api/api";

const Sidebar = () => {
  const [leftOpen, setLeftOpen] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);
  const [showDialoge, setShowDialog] = useState(false)
  const status = useSelector((state) => state.sidebar.value);
  const dispatch = useDispatch();
  const location = useLocation();
  let token = localStorage.getItem("@property-token");

  let {companyId} = GET_COMPANY_ENV()

  const myObjectString = localStorage.getItem("@property-userData");
  let myObject;
  if (myObjectString) {
    myObject = JSON.parse(myObjectString);
  }

  useEffect(() => {
    if (leftOpen) dispatch(sidebarStatus("open"));
    else dispatch(sidebarStatus("closed"));
  }, [leftOpen]);

  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

const confirmlogOut = ()=>{
      dispatch(authStatus(false));
    const updatedStore = { auth: false };
    // window.localStorage.setItem(
    //   "@property-userData",
    //   JSON.stringify(updatedStore)
    // );
    localStorage.clear()
    redirect("/login");
    window.location.reload();
}

const logOut = (event) => {
  setShowDialog((prevState => !prevState))
  if(event == true){
confirmlogOut()
setShowDialog(false)
  }
  if(!event){
    setShowDialog(false)
  }
};

  useEffect(() => {
    const handleCheckAdmin = async () => {
      const admin = await getUserById(myObject.userData?.id);
      if (admin.user.isAdmin) {
        setIsAdmin(true);
        dispatch(checkRole(true));
      } else {
        setIsAdmin(false);
        dispatch(checkRole(false));
      }
    };

    if(myObject.userData?.id !== undefined)
      handleCheckAdmin();
  }, []);

  const displayIcon = () => {
    if(leftOpen)
    return <ArrowBackIosIcon className="iconAction icoSidebar" sx={{ color: 'white' }} fontSize="large" onClick={() => setLeftOpen(!leftOpen)}/>
    else return <ArrowForwardIosIcon className="iconAction icoSidebar-closed" sx={{ color: 'white' }} fontSize="large" onClick={() => setLeftOpen(!leftOpen)}/>
  }

  return (
    <>
    {showDialoge &&
        <AlertDialog confirmButton={logOut}>Are you sure you want to logout?</AlertDialog>
    }
      <div id="left" className={leftOpen.toString()}>
        <div className={`sidebar ${status.toString()}`}>
          <div className="header">
            <img src={logo} alt="Property Gauge"/>
            {
              displayIcon()
            }
          </div>
          <div className="content">
            <div className="parent">
              <div className="user-info">
                <p style={{ marginBottom: "0.2rem" }}>
                  <b>Customer Company: {companyId}</b>
                </p>
                <p>{myObject?.userData?.email}</p>
              </div>
              <Link
                to="/overview"
                className={`${
                  location.pathname === "/overview" ? "__actual" : ""
                }`}
              >
                Overview
              </Link>
              <Link
                to="/leasing"
                className={`${
                  location.pathname === "/leasing" ? "__actual" : ""
                }`}
              >
                Leasing
              </Link>
              <Link
                to="/occupancy"
                className={`${
                  location.pathname === "/occupancy" ? "__actual" : ""
                }`}
              >
                Occupancy
              </Link>
              <Link
                to="/maintenance"
                className={`${
                  location.pathname === "/maintenance" ? "__actual" : ""
                }`}
              >
                Maintenance
              </Link>
              <Link
                to="/collections"
                className={`${
                  location.pathname === "/collections" ? "__actual" : ""
                }`}
              >
                Collections
              </Link>
              <Link
                to="/budget"
                className={`${
                  location.pathname === "/budget" ? "__actual" : ""
                }`}
              >
                Budget
              </Link>
            </div>
            <div className="parent">
              {isAdmin ? (
                <Link
                  to="/admin"
                  className={`${
                    location.pathname === "/admin" ? "__actual" : ""
                  }`}
                >
                  Admin
                </Link>
              ) : (
                <></>
              )}
              <Link
                to="/settings"
                className={`${
                  location.pathname === "/settings" ? "__actual" : ""
                }`}
              >
                Settings
              </Link>
              <Link
                to="/help"
                className={`${location.pathname === "/help" ? "__actual" : ""}`}
              >
                Help
              </Link>
              <a onClick={logOut}>Logout</a>
              <p>{`© Property Gauge ${getCurrentYear()}`}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="toggleClosed">
        <img
          className={`toogleItem ${status.toString()}`}
          onClick={() => setLeftOpen(!leftOpen)}
          src={tab}
          alt = "Toggle"
        />
      </div>
    </>
  );
};

export default Sidebar;
