import React from 'react'
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from 'react-router-dom';
import { getBudgetDetails } from '../../../api/api';
import useFilter from '../../../hooks/useFilter';
import { useSelector } from 'react-redux';
import useFilterResponse from '../../../hooks/useFilterResponse';
import { MoreDetails } from '../tables/moreDetails/moreDetails';
import TablePagination from '@mui/material/TablePagination';

export const BudgetDetails = () => {
    const navigate = useNavigate();
    const { handleToggleFilter } = useFilter();
  
    const status = useSelector((state) => state.sidebar.value);
  
    const {response, loader, PagResponse, fetchData, setLoader} = useFilterResponse(getBudgetDetails, "data", "", true)
  
    const handleRedirect = () => navigate("/budget");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (event, newPage) => {
      setLoader(true)
      setPage(newPage);
      fetchData({rows: rowsPerPage, page: newPage + 1})
    };

    const handleChangeRowsPerPage = (event) => {
      console.log(event.target.value)
      setLoader(true)
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);

      fetchData({rows: event.target.value, page: 1})
    };


  return (
    <div id="main">
      <div className={`content ${status}`} style={{height: '100vh'}}>
        <div className={`occupancy-renew ${status}`} style={{height: 'auto'}}>
          <div className="top">
            <h2>Budget Variance</h2>
            <button className="blue" onClick={handleToggleFilter}>
              PROPERTIES FILTERS
            </button>
          </div>
          <div className="line"></div>
          <div className="second-top">
            <button onClick={handleRedirect}>{"<"} BACK</button>
            {/* <select></select> */}
          </div>
          <div style={{ marginBottom: "2rem"}}>
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : response?.length >= 0 ? (<>
              <MoreDetails data={response}/>
              <TablePagination
                count={PagResponse?.totalRows}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
            ) : (
              <div style={{ textAlign: "center" }}>No data</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
