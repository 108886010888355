import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getPropertiesGroups } from "../../api/api";
import { useDispatch } from "react-redux";
import { editGroupList } from "../../storage/stateSlice";
import { filterGroups } from "../../storage/filterSlice";
const ENUM_OPTIONS = {
  DO_NOTHING:0,
  CHECKED:1,
  UNCHECKED:2,
}
export const GroupsForm = ({
  selected = [],
  groups= [],
  edit = false,
  checkAll = ENUM_OPTIONS.DO_NOTHING,
  filter = false,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);


  useEffect(() => {
    const fetchProperties = async () => {
      try {
        await getPropertiesGroups().then((res) => {
          if (edit) {
            handleCheckSelected(res.propertyGroups)
          } else {
            setData(res.propertyGroups);
          }
        });
      } catch (error) {
        if (
          error.response.data.resetUser &&
          error.response.data.msg === "Token invalid"
        ) {
          // Handle token invalid error
        }
      }
    };

    if (!filter) fetchProperties();
    else {
      //setData(selected);
      if(selected.length > 0){
        handleCheckSelected(groups)
      }else{
        setData(groups)
      }
    }
  }, []);

  useEffect(() => {
    dispatch(editGroupList(data));

    if (filter) dispatch(filterGroups(data));
  }, [data, dispatch]);

  useEffect(() => {
    if (checkAll === ENUM_OPTIONS.CHECKED) {
      setData((prevData) =>
        prevData.map((item) => ({
          ...item,
          value: 1,
        }))
      );
    } 
    if(checkAll === ENUM_OPTIONS.UNCHECKED) {
      setData((prevData) =>
        prevData.map((item) => ({
          ...item,
          value: 0,
        }))
      );
    }
    /*if(selected.length> 0 && checkAll === ENUM_OPTIONS.DO_NOTHING){
      handleCheckSelected(groups)
    }*/
  }, [checkAll]);

  const handleCheckSelected = (array) => {
    const updatedArray = [...array];
            for (let i = 0; i < updatedArray?.length; i++) {
              for (let j = 0; j < selected?.length; j++) {
                if (updatedArray[i]?.id === selected[j]?.id) {
                  // Create a new object with the updated value property
                  const updatedObject = {
                    ...updatedArray[i],
                    value: 1,
                  };
                  // Replace the object in the updatedArray
                  updatedArray[i] = updatedObject;
                }
                // }else {
                //   // Create a new object with the updated value property
                //   const updatedObject = {
                //     ...updatedArray[i],
                //     value: 0,
                //   };
                //   // Replace the object in the updatedArray
                //   updatedArray[i] = updatedObject;
                // }
              }
            }
    setData(updatedArray);
  }

  const handleChange = (event, id) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: event.target.checked ? 1 : 0 } : item
      )
    );
  };

  return (
    <div className="list-property-content">
      {data?.map((value, index) => {
        return (
          <>
            <div key={index} className="item">
              <FormControlLabel
                value={value?.id.toString()}
                label={value?.groupName}
                labelPlacement="start"
                control={
                  <Checkbox
                    id={value?.id.toString()}
                    name={value?.id.toString()}
                    checked={value?.value === 1}
                    onChange={(event) => {
                      //if(checkAll === ENUM_OPTIONS.CHECKED || checkAll === ENUM_OPTIONS.UNCHECKED)
                      //checkAll = ENUM_OPTIONS.DO_NOTHING
                      handleChange(event, value?.id)
                    }}
                    sx={{
                      color: "#003A5F",
                      "&.Mui-checked": {
                        color: "#003A5F",
                      },
                    }}
                  />
                }
              />
            </div>
            <div className="line" style={{ margin: "0.2rem 0" }}></div>
          </>
        );
      })}
    </div>
  );
};
