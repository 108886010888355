import React, {useState, useRef, useEffect} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";

const Schedules = ({ data }) => {

data = data.map((data)=>{
  return {
    id: data.id,
    propertyID: data.propertyID,
    ...data
  }
})

  const totalvacant = data?.reduce((acc, curr) => acc + curr?.vacant, 0);
  const totalmoveOuts = data?.reduce((acc, curr) => acc + curr?.moveOuts, 0);
  const totalmoveIns = data?.reduce((acc, curr) => acc + curr?.moveIns, 0);
  const totalapprovedApps = data?.reduce(
    (acc, curr) => acc + curr?.approvedApps,
    0
  );
  const totalpendingApps = data?.reduce(
    (acc, curr) => acc + curr?.pendingApps,
    0
  );
  const totalexpect = data?.reduce((acc, curr) => acc + curr?.expect, 0);
  const total = data?.reduce((acc, curr) => acc + curr?.pendingRenewals, 0);

  const height = useTableHeight(data)

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const vwToPx = (vw) => (vw * width) / 100;

  const apiRef = useGridApiRef();


  const columnNames = [
    {name:"Property", type: "singleSelect", width: vwToPx(25) },
    {name:"Vacant", type:'number', width: vwToPx(6)},
    {name:"Move Outs", type: "number", width: vwToPx(10)},
    {name:"Move Ins", type:'number', width: vwToPx(10)},
    {name:"Approved Apps", type:'number', width: vwToPx(12)},
    {name:"Pending Apps", type:'number', width: vwToPx(12)},
    {name:"Pending Renewals", type:'number', width: vwToPx(12)},
    {name:"Expected Vacancies", type:'number', width: vwToPx(12)},
  ];

  let { columenGen } = useMUIFilter(
    data,
    columnNames,
    "Scheduled Move Ins & Move Outs"
  );

  let columns = columenGen();

  return (
    <div className="container-table" style={{ height: height }} id='ScheduledMoveIns&MoveOut' ref={elementRef}>
<DataGridPremium
  className="grid-style"
  apiRef={apiRef}
  rows={data}
  columns={columns}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  pinnedColumns={{left: ['name']}}
  disableVirtualization
  hideFooter
  disableColumnSelector
  initialState={{
    aggregation: {
      model: {
        vacant: 'sum',
        moveOuts: 'sum',
        moveIns:'sum',
        approvedApps:'sum',
        pendingApps:'sum',
        pendingRenewals:'sum',
        expect:'sum',
      },
    },
  }}
/>
    </div>
  );
};

export default Schedules;


// import React from "react";
// import useTableHeight from "../../../../hooks/useTableHeight";

// const Schedules = ({ data }) => {
//   const totalvacant = data?.reduce((acc, curr) => acc + curr?.vacant, 0);
//   const totalmoveOuts = data?.reduce((acc, curr) => acc + curr?.moveOuts, 0);
//   const totalmoveIns = data?.reduce((acc, curr) => acc + curr?.moveIns, 0);
//   const totalapprovedApps = data?.reduce(
//     (acc, curr) => acc + curr?.approvedApps,
//     0
//   );
//   const totalpendingApps = data?.reduce(
//     (acc, curr) => acc + curr?.pendingApps,
//     0
//   );
//   const totalexpect = data?.reduce((acc, curr) => acc + curr?.expect, 0);
//   const total = data?.reduce((acc, curr) => acc + curr?.pendingRenewals, 0);

//   const height = useTableHeight(data)

//   return (
//     <div className="container-table" style={{ height: height }} id='ScheduledMoveIns&MoveOut'>
//       <div className="wrapper">
//         <table>
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th>Vacant</th>
//               <th>Move Outs</th>
//               <th>Move Ins</th>
//               <th>Approved Apps</th>
//               <th>Pending Apps</th>
//               <th>Pending Renewals</th>
//               <th>Expected Vacancies</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td className={`${index%2 === 0 ? 'white' : ''}`}>{item?.name}</td>
//                 <td>{item?.vacant}</td>
//                 <td>{item?.moveOuts}</td>
//                 <td>{item?.moveIns}</td>
//                 <td>{item?.approvedApps}</td>
//                 <td>{item?.pendingApps}</td>
//                 <td>{item?.pendingRenewals}</td>
//                 <td>{item?.expect}</td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{totalvacant}</td>
//               <td>{totalmoveOuts}</td>
//               <td>{totalmoveIns}</td>
//               <td>{totalapprovedApps}</td>
//               <td>{totalpendingApps}</td>
//               <td>{total}</td>
//               <td>{totalexpect}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Schedules;
