import React, { useState, useEffect } from "react";
import "../../styles.scss";
import "./budget.scss";
import { useSelector } from "react-redux";
import NetIncomeT from "./tables/netIncome";
import EvBTable from "./tables/EvB";
import IvBTable from "./tables/IvB";
import {
  getExpense,
  getIncomes,
  getNetIncomes,
  getTotalNetIncomes,
} from "../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import SelectProperty from "./tables/select";
import FilterButton from "../../components/filterButton";
import useFilterResponse from "../../hooks/useFilterResponse";
import { useNavigate } from "react-router-dom";

const Budget = () => {
  const status = useSelector((state) => state.sidebar.value);
  const [toggle, setToggle] = useState(true);
  const {response: netData, loader: netLoader, fetchData: refetchDataNI} = useFilterResponse(getNetIncomes,'netIncome')
  const {response: EvBData, loader: EvBLoader, fetchData: refetchDataE} = useFilterResponse(getExpense,'expenseAndBudget')
  const {response: IvBData, loader: IvBLoader, fetchData: refetchDataI} = useFilterResponse(getIncomes,'incomeAndBudget')
  const {response: totalData, loader: totalLoader, fetchData: refetchDataTI} = useFilterResponse(getTotalNetIncomes,'totalNetIncome')

useEffect(() => {
  refetchDataNI()
  refetchDataE()
  refetchDataI()
  refetchDataTI()
}, [localStorage.getItem('@properties-filter')]);

  const setTable = (event) => {
    if (event.target.value === "EvB") {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const vsBudget = () => {
    if (toggle) {
      if (EvBLoader) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        );
      } else {
        if (EvBData.length > 0) return <EvBTable data={EvBData} />;
        else return <div style={{ textAlign: "center" }}>No data</div>;
      }
    } else {
      if (IvBLoader)
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        );
      else {
        if (IvBData.length > 0) return <IvBTable data={IvBData} />;
        else return <div style={{ textAlign: "center" }}>No data</div>;
      }
    }
  };
  const navigate = useNavigate();

  const handleRedirectButton = () => navigate("/budget-details")

  return (
    <>
      <div id="main">
        <FilterButton />
        <div className={`content ${status}`}>
          <div className="budget-container">
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Net Income</h2>
                <button onClick={handleRedirectButton}>
                  MORE DETAILS
                </button>
              </div>
              <div className="line"></div>
              {netLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <NetIncomeT data={netData} />
              )}
            </div>
            <div className={`child ${status}`}>
              <h2>Total Net Operating Income (all selected properties)</h2>
              <div className="line"></div>
              {totalLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <SelectProperty data={totalData} />
              )}
            </div>
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2> {toggle ? "Expenses vs Budgets" : "Income vs Budgets"}</h2>
                <div className="radiobContainer">
                  <strong>Showing:</strong>
                  <div onChange={setTable.bind(this)} className="radios">
                    <div>
                      <input
                      style={{ cursor: 'pointer' }}
                        type="radio"
                        value="EvB"
                        name="gender"
                        id="gold"
                        checked={toggle}
                      />
                      Expenses vs Budgets
                    </div>
                    <div>
                      <input type="radio" value="IvB" name="gender" id="gold" style={{ cursor: 'pointer' }}/>
                      Income vs Budgets
                    </div>
                  </div>
                </div>
              </div>
              <div className="line"></div>
              {vsBudget()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Budget;
