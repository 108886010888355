import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    isAdmin: true,
    userData: {},
    firebaseToken: "",
    token: "",
    properties: [],
    groupsProperty: [],
  },
  reducers: {
    authStatus: (state, action) => {
      state.isAuth = action.payload;
    },
    userData: (state, action) => {
      state.userData = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    firebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    checkRole: (state, action) => {
      state.isAdmin = action.payload;
    },
    setPropertiesUser: (state, action) => {
      state.properties = action.payload;
    },
    setGroupsProperty: (state, action) => {
      state.groupsProperty = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  authStatus,
  userData,
  setToken,
  firebaseToken,
  checkRole,
  setPropertiesUser,
  setGroupsProperty,
} = authSlice.actions;

export default authSlice.reducer;
