import React, { useState, useEffect } from "react";
import "../../styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUsers, getProperties, getPropertiesGroups } from "../../api/api";
import Users from "./tables/users";
import Groups from "./tables/groups";
import Properties from "./tables/properties";
import CircularProgress from "@mui/material/CircularProgress";

const Admin = ({ onEnter }) => {
  const navigate = useNavigate();

  const status = useSelector((state) => state.sidebar.value);
  const auth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  const [users, setUsers] = useState();
  const [groups, setGroups] = useState();
  const [properties, setProperties] = useState();
  const [usersLoader, setUsersLoader] = useState(true);
  const [groupsLoader, setGroupsLoader] = useState(true);
  const [propertiesLoader, setPropertiesLoader] = useState(true);

  const fetchData = async () => {
    try {
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers.users);
      setUsersLoader(false);

      const fetchedProperties = await getProperties();
      setProperties(fetchedProperties.properties);
      setPropertiesLoader(false);

      const fetchedCompanies = await getPropertiesGroups();
      setGroups(fetchedCompanies.propertyGroups);
      setGroupsLoader(false);
    } catch (error) {
      console.log(error);
      setUsersLoader(false);
      setPropertiesLoader(false);
      setGroupsLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [auth]);

  useEffect(() => {
    onEnter();
  }, [onEnter]);

  const handleRedirectCreateUserButton = () => navigate("/createUser");
  const handleRedirectCreateGroupButton = () => navigate("/createGroup");
  const handleRedirectThresholdsButton = () => navigate("/thresholds");

  return (
    <>
      <div id="main">
        <div className="header">
          <h3
            className={`
                      title
                      ${"left-" + status}
                  `}
          >
            <button
              style={{ display: "none" }}
              className="blue"
              onClick={handleRedirectThresholdsButton}
            >
              THRESHOLDS
            </button>
          </h3>
        </div>
        <div className={`content ${status}`}>
          <div className="leasing-container">
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Users</h2>
                <button
                  onClick={handleRedirectCreateUserButton}
                  className="blue"
                >
                  ADD USER
                </button>
              </div>
              <div className="line"></div>
              {usersLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <Users data={users} />
              )}
            </div>
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Groups</h2>
                <button
                  onClick={handleRedirectCreateGroupButton}
                  className="blue"
                >
                  NEW GROUP
                </button>
              </div>
              <div className="line"></div>
              {groupsLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <Groups data={groups} />
              )}
            </div>
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Properties</h2>
              </div>
              <div className="line"></div>
              {propertiesLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <Properties data={properties} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
