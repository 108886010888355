import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import "./fonts/Guminert Regular.ttf";
import Login from "./views/login/login";
import Dashboard from "./dashboard";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation, useSearchParams } from "react-router-dom";
import ResetPassword from "./views/resetPassword/ResetPassword";
import { validateTokenAndEmail } from "./api/api";

function App() {
  return (
    <div>
      <MainProvider></MainProvider>
      <RedirectLogin />
    </div>
  );
}

const MainProvider = () => {

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const storeState = useSelector((state) => state.auth.isAuth);
  const previousStatusRef = useRef(storeState);
  const [status, setStatus] = useState(
    JSON.parse(window.localStorage.getItem("@property-userData"))?.auth ??
      storeState
  );
  const [validToken,setValidToken] = useState(true)

  const handleConditionalViews = () => {

    (async function resetRouteGuard(){
      let email = searchParams.get('email')
      let token = searchParams.get('token')
      if(email && token){
        let response = await validateTokenAndEmail(email,token)
      if(!response){
        setValidToken(false)
      }
      }
    })()

    if (status) {
      return <Dashboard />;
    } else {
      // if (location.pathname !== "/reset-password/:id") return <Login />;
      if (!location.pathname.includes('setNewPassword')) return <Login />;
      if(!validToken) return <Login/>
      return <ResetPassword/>
    }
  };

  useEffect(() => {
    const previousStatus = previousStatusRef.current;
    if (previousStatus !== storeState) {
      setStatus(storeState);
    }
    previousStatusRef.current = storeState;
  }, [storeState]);

  return <>{handleConditionalViews()}</>;
};

const RedirectLogin = () => {
  const storeState = useSelector((state) => state.auth.isAuth);
  const previousStatusRef = useRef(storeState);
  const [status, setStatus] = useState(
    JSON.parse(window.localStorage.getItem("@property-userData"))?.auth ??
      storeState
  );

  useEffect(() => {
    const previousStatus = previousStatusRef.current;
    if (previousStatus !== storeState) {
      setStatus(
        JSON.parse(window.localStorage.getItem("@property-userData"))?.auth ??
          storeState
      );
    }
    previousStatusRef.current = storeState;
  }, [storeState]);

  return (
    <>
      {status ? (
        <Routes>
          <Route path="/login" element={<Navigate to="/overview" />} />
          <Route path="setNewPassword" element={<ResetPassword />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="setNewPassword" element={<ResetPassword />} />
        </Routes>
      )}
    </>
  );
};

export default App;
