const GET_ENV = () => {
    
    if(process.env.REACT_APP_ENV){
        if(process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'dev')
            return 'DEV';
        if(process.env.REACT_APP_ENV === 'staging')
            return 'STAGING';
        if(process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'prod')
            return 'PROD';
        if(process.env.REACT_APP_ENV === 'local')
            return 'LOCAL';
        if(process.env.REACT_APP_ENV === 'demo')
            return 'DEMO';
    }
    else
        throw 'process.env.ENV returns unknown value';
}

export default GET_ENV;