import { useState, useEffect } from "react";

const useTableHeight = (data, rowHeight= 70, totalHeight = '530px') => {
  const [height, setHeight] = useState(totalHeight);

  useEffect(() => {
    if (data?.length <= 5) {
      let len = (data.length + 1) * rowHeight;
      setHeight(len.toString() + "px");
      // if(data?.length === 1 || data?.length === 2){
      if(data?.length === 1 || data?.length === 2){
        len = (data.length + 2) * rowHeight;
        setHeight(len.toString() + "px");
      }
    }else setHeight(totalHeight)
  }, [data]);

  return height;
};

export default useTableHeight;
