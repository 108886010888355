import { useSelector } from "react-redux";

export const useThreshold = ({ view, table, loader = true }) => {
  const states = {
    leasing: useSelector((state) => state.threshold.leasing),
    occupancy: useSelector((state) => state.threshold.occupancy),
    maintenance: useSelector((state) => state.threshold.maintenance),
    collections: useSelector((state) => state.threshold.collections),
    budget: useSelector((state) => state.threshold.budget),
  };

  function getThresholdData(value, column) {
    if (!loader) {
      const tableData = states[view].find((entry) => entry["#text"] === table);

      if (tableData) {
        // finding column data
        if (table === "MoreDetailsMaintenance") {
          return getColorBasedOnThresholds(
            value,
            tableData.kpi_3_tier.upperThresholdValue,
            tableData.kpi_3_tier.middleThresholdValue,
            tableData.kpi_3_tier.upperThresholdColor,
            tableData.kpi_3_tier.middleThresholdColor,
            tableData.kpi_3_tier.lowThresholdColor
          );
        } else if (view === "budget") {
          if (Array.isArray(tableData?.kpi_5_tier)) {
            const columnData = tableData?.kpi_5_tier?.find(
              (kpi) => kpi["#text"] === column
            );

            return getColorBasedOnMultipleThresholds(value, columnData);
          } else {
            return getColorBasedOnMultipleThresholds(
              value,
              tableData.kpi_5_tier
            );
          }
        } else {
          const columnData = tableData?.kpi_3_tier?.find(
            (kpi) => kpi["#text"] === column
          );

          if (columnData) {
            // get threshold values
            const upperThresholdColor = columnData.upperThresholdColor;
            const upperThresholdValue = columnData.upperThresholdValue;
            const middleThresholdColor = columnData.middleThresholdColor;
            const middleThresholdValue = columnData.middleThresholdValue;
            const lowThresholdColor = columnData.lowThresholdColor;

            // return color
            return getColorBasedOnThresholds(
              value,
              upperThresholdValue,
              middleThresholdValue,
              upperThresholdColor,
              middleThresholdColor,
              lowThresholdColor
            );
          }
        }
      }
    } else return "null"; // return null if doesn't exits data
  }

  function getColorBasedOnThresholds(
    value,
    upperThresholdValue,
    middleThresholdValue,
    upperThresholdColor,
    middleThresholdColor,
    lowThresholdColor
  ) {
    if (value >= parseFloat(upperThresholdValue)) {
      return upperThresholdColor;
    } else if (value >= parseFloat(middleThresholdValue)) {
      return middleThresholdColor;
    } else {
      return lowThresholdColor;
    }
  }

  function getColorBasedOnMultipleThresholds(value, thresholds) {
    if (value >= parseFloat(thresholds.FifthThresholdValue)) {
      return thresholds.FifthThresholdColor;
    } else if (value >= parseFloat(thresholds.FourthThresholdValue)) {
      return thresholds.FourthThresholdColor;
    } else if (value >= parseFloat(thresholds.ThirdThresholdValue)) {
      return thresholds.ThirdThresholdColor;
    } else if (value >= parseFloat(thresholds.SecondThresholdValue)) {
      return thresholds.SecondThresholdColor;
    } else {
      return thresholds.FirstThresholdColor;
    }
  }

  return { getThresholdData };
};
