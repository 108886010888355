import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserById, updateUser } from "../../api/api";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../../styles.scss";
import "./settings.scss";

const Settings = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  let id = localStorage.getItem("@property-userData");
  let json = JSON.parse(id);
  const userID = json.userData.id;

  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [alertText, setAlertText] = useState("");

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    checkPassword: "",
    title: "",
    gender: "",
    properties: [],
    propertyGroup: [],
  });

  const [hasErrors, setHasErrors] = useState({
    firstname: true,
    lastname: true,
    email: true,
    password: true,
    checkPassword: true,
    title: true,
    gender: true,
  });
  // Handle click event of admin button
  const handleRedirectAdminButton = () => navigate("/admin");

  // Handle input change of user form
  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
    setHasErrors({
      ...hasErrors,
      [event.target.name]: false,
    });
  };

  const handleSave = () => {
    console.log(user);
    let errors = { ...hasErrors };
    let hasError = false;

    Object.keys(user).forEach((key) => {
      if (!user[key] === undefined) {
        errors[key] = true;
        hasError = true;
      }
    });

    if (user.password !== user.checkPassword) {
      errors.password = true;
      errors.checkPassword = true;
      hasError = true;
    }

    setHasErrors(errors);

    if (hasError) {
      console.log("Hay errores en el formulario");
      return;
    }

    console.log("El formulario es válido, se puede enviar");
    fetchedUpdateUserInfo();
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const fetchedUpdateUserInfo = async () => {
    try {
      const edit = await updateUser(userID, user);
      console.log(edit);
      setError(false);
      setAlertText("User created successfully");
      setShow(true);
      handleRedirectAdminButton();
    } catch (e) {
      console.log(e);
      setError(true);
      setAlertText("Server error");
      setShow(true);
    }
  };

  const fetchedGetUserInfo = async () => {
    try {
      const userInfo = await getUserById(userID);
      setUser({
        ...user,
        firstname: userInfo?.user?.firstName,
        lastname: userInfo?.user?.lastName,
        email: userInfo?.user?.email,
        title: userInfo?.user?.title,
        gender: userInfo?.user?.isAdmin ? "Admin" : "User",
        properties: userInfo?.user?.properties,
        propertyGroups: userInfo?.user?.propertyGroups,
      });

      setHasErrors({
        firstname: false,
        lastname: false,
        email: false,
        password: false,
        checkPassword: false,
        title: false,
        gender: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
  };

  useEffect(() => {
    fetchedGetUserInfo();
  }, []);
  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className="settings-container">
          <Snackbar
            autoHideDuration={3000}
            onClose={handleClose}
            open={show}
            sx={{ height: "100%" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleClose}
              severity={error ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {alertText}
            </Alert>
          </Snackbar>
          <div>
            <h2>{user?.firstname + " " + user?.lastname}</h2>
            <div className="line"></div>
          </div>
          <div className="content-mid">
            <div>
              <div style={{ margin: "2rem 2rem 0 2rem" }}>
                <h3 style={{ marginBottom: "1rem" }}>User Information</h3>
                <div style={{ display: "flex" }}>
                  Role:{" "}
                  <p style={{ color: "#0199AD", marginLeft: "5px" }}>
                    {user.gender}
                  </p>
                </div>
              </div>
              <div className="settings-middle">
                <label>First Name</label>
                <input
                  className={hasErrors.firstname ? "error" : ""}
                  value={user.firstname}
                  name="firstname"
                  type="text"
                  onChange={handleInputChange}
                ></input>
                <label>Last Name</label>
                <input
                  className={hasErrors.lastname ? "error" : ""}
                  value={user.lastname}
                  name="lastname"
                  type="text"
                  onChange={handleInputChange}
                ></input>
                <label>Title</label>
                <input
                  className={hasErrors.title ? "error" : ""}
                  value={user.title}
                  name="title"
                  type="text"
                  onChange={handleInputChange}
                ></input>
                <label>Email</label>
                <input
                  className={hasErrors.email ? "error" : ""}
                  value={user.email}
                  name="email"
                  type="text"
                  onChange={handleInputChange}
                ></input>
                <label>Password</label>
                <input
                  className={hasErrors.password ? "error" : ""}
                  value={user.password}
                  name="password"
                  type="password"
                  onChange={handleInputChange}
                ></input>
                <label>Confirm Password</label>
                <input
                  className={hasErrors.checkPassword ? "error" : ""}
                  value={user.checkPassword}
                  name="checkPassword"
                  type="password"
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ margin: "0 2rem 0 2rem" }}>
            <br></br>
          </div>
          <div className="settings-bottom">
            <div>
              <button className="gray" onClick={handleRedirectAdminButton}>
                CANCEL
              </button>
            </div>
            <div>
              <button className="blue" onClick={handleSave}>
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
