import React from "react";
import SelectColor from "../components/select";

const LeasingTab = () => {
  return (
    <>
      <div className="top-selector">
        At what point do you consider an apartment leased?{" "}
        <button>Application Completed</button>
      </div>
      <SelectColor question="How many days should it take to process an aplication?"/>
      <SelectColor question="How many days should it take an application to sign their lease?"/>
      <SelectColor question="How many days are you willing to hold an apartment after is ready?"/>
      <SelectColor question="How many leads should it take to schedule a showing?"/>
      <SelectColor question="How many showings should it take to take an application?"/>
    </>
  );
};

export default LeasingTab;
