import React, { useState, useEffect } from "react";
import "../../../../../styles.scss";
import "../../../../settings/settings.scss";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PropertiesForm } from "../../../../../components/propertiesForm";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getPropertyGroups, putPropertiesGroups } from "../../../../../api/api";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const EditGroup = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [selected, setSelected] = useState([]);
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [severity, setSeverity] = useState("error"); //success
  const [load, setLoad] = useState(true);

  const status = useSelector((state) => state.sidebar.value);
  const list = useSelector((state) => state.state.propertiesList);

  const handleRedirectAdminButton = () => navigate("/admin");

  const handleCreateGroup = async () => {
    setShow(false);

    const filter = list.filter((item) => item.value === 1);
    if (filter.length === 0) {
      setText("Select any property for creating a new group");
      setSeverity("error");
      setShow(true);
    } else {
      if (groupName !== "") {
        const res = await putPropertiesGroups(groupId, {
          groupName: groupName,
          properties: filter,
        });
        if (res.propertyGroup.id !== "") {
          setText("Sucess");
          setSeverity("success");
          setShow(true);
          handleRedirectAdminButton();
        }
      } else {
        setText("Group Name input is empty");
        setSeverity("error");
        setShow(true);
      }
    }
  };

  const fetchedGetProperty = async () => {
    await getPropertyGroups(groupId).then((res) => {
      setGroupName(res?.propertyGroup?.groupName);
      setSelected(res?.propertyGroup?.properties);
      setLoad(false);
    });
  };

  const handlePropertiesList = () => {
    if (load) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "3rem 0",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return <PropertiesForm selected={selected} edit={true} />;
    }
  };

  useEffect(() => {
    fetchedGetProperty();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  return (
    <div id="main">
      <Snackbar
        autoHideDuration={3000}
        onClose={handleClose}
        open={show}
        sx={{ height: "100%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }} onClose={handleClose}>
          {text}
        </Alert>
      </Snackbar>
      <div className={`content ${status}`}>
        <div className="settings-container">
          <div>
            <h2>Edit Group</h2>
            <div className="line"></div>
          </div>
          <div className="content-mid">
            <div className="create-group-inputs">
              <div style={{ margin: "2rem 2rem 0 2rem" }}>
                <h3 style={{ marginBottom: "1rem" }}>Group Name</h3>
                <input
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ margin: "2rem 2rem 0 2rem" }}>
              <h3>Properties</h3>
              {handlePropertiesList()}
            </div>
          </div>
          <div style={{ margin: "0 2rem 0 2rem" }}>
            <br></br>
          </div>
          <div className="settings-bottom">
            <div>
              <button className="gray" onClick={handleRedirectAdminButton}>
                CANCEL
              </button>
            </div>
            <div>
              <button className="blue" onClick={handleCreateGroup}>
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
