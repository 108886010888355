import { useState, useEffect } from "react";

const useSetFilterSelect = (array, fields) => {
  const [data, setData] = useState(array);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getFilters = (arrayOfObjects, fields) => {
      const uniqueValues = [];

      for (const field of fields) {
        const uniqueValuesSet = new Set();
        for (const object of arrayOfObjects) {
          uniqueValuesSet.add(object[field]);
        }
        uniqueValues.push({
          name: field,
          options: Array.from(uniqueValuesSet),
        });
      }

      setData(uniqueValues);
      setLoader(false);
    };

    if (array.length > 0) {
      setLoader(true);
      getFilters(array, fields);
    }
  }, [array]);

  return { dataFilter: data, loaderFilter: loader };
};

export default useSetFilterSelect;
