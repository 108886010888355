import * as React from 'react'
import toolTips from '../config/tooltips.json'
import { Tooltip, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';

export default function useMUIFilter(res, columnName, header){

let singleSelectArrayGen = (key)=>{
    return [...new Set(res.map(item => item[key]))];
}

let fieldName = Object.keys(res[0])

    function columenGen(){
        columnName?.map((columns, index)=>{
            columns.field = fieldName[index+2] || columns.field
            columns.headerName = columns.name;
            // columns.sortable = false
            columns.colNum = index;
            columns.hideable = false;
            columns.pinnable = false;
            columns.managecolumns = false;
            columns.headerAlign = 'center';
            if(columns.type === 'singleSelect'){
                columns.valueFormatter = ({ value }) => value;
                columns.valueOptions = singleSelectArrayGen(columns.field)
            }
            if(columns.type === 'date'){
                columns.valueFormatter = (params) => {return params.value};
            }
            if(columns.type === 'number'){
                columns.availableAggregationFunctions = ['min', 'max', 'sum', 'avg'];
            }
            // if(header && columns.headerName === toolTips[header][index].name && toolTips[header][index].tooltip.header){ upon uncomment rewrite new work orders tooltips
                if(header && toolTips[header][index].name && toolTips[header][index].tooltip.header){
                columns.renderHeader =  (params)=>{ 
                    return(
                        <>
                        <Tooltip 
                        placement="top" 
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={
                            <React.Fragment>
                              <Typography color="inherit">{toolTips[header][index].tooltip.header}</Typography>
                              {toolTips[header][index].tooltip.text}
                            </React.Fragment>
                          }>
                            <p>{columns.headerName}</p>
                          </Tooltip>
                        </>
                    )}
            }
        })
        return columnName
    }

//     function thresHolds(params, thresHoldFN, thresHoldValues){
//         if(thresHoldValues.hasOwnProperty(params.field) && params.row[params.field]){
//             return `${thresHoldFN(params.row[params.field], params.field)}`
//         }
//         return ''
//     }

function thresHolds(params, thresHoldFN, thresHoldValues){
    let thresHoldName = thresHoldValues[params.field]?.thresHoldValue
    if(thresHoldName && params.row[params.field]){
        return `${thresHoldFN(params.row[params.field], thresHoldName)}`
    }
    return ''
}

    function notesColumnNameGen(){
        return columnName.map((col)=>{
 return col.name;
        })
    }

    return {columenGen, thresHolds, notesColumnNameGen}
}