import { createSlice } from "@reduxjs/toolkit";

export const stateSlice = createSlice({
  name: "state",
  initialState: {
    editUserId: "",
    editGroupId: "",
    propertiesList: [],
    groupList: [],
  },
  reducers: {
    editUserIdStatus: (state, action) => {
      state.editUserId = action.payload;
    },
    editPropertiesList: (state, action) => {
      state.propertiesList = action.payload;
    },
    editGroupIdStatus: (state, action) => {
      state.editGroupId = action.payload;
    },
    editGroupList: (state, action) => {
      state.groupList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  editUserIdStatus,
  editPropertiesList,
  editGroupIdStatus,
  editGroupList,
} = stateSlice.actions;

export default stateSlice.reducer;
