import { React } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
  Legend,
  Tooltip,
} from "chart.js";

Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Filler);
Chart.register(Legend);
Chart.register(Tooltip);

const LineChart = ({ info }) => {

  const colorPalette = [
    "rgba(250,174,50,1)",
    "rgba(75,192,192,1)",
    "rgba(255,99,132,1)",
    "rgba(54,162,235,1)",
    "rgba(255,206,86,1)",
    "rgba(153,102,255,1)",
    "rgba(255, 128, 0, 1)",
    "rgba(0, 128, 128, 1) ",
    "rgba(255, 51, 51, 1)",
    "rgba(102, 204, 0, 1)",
    "rgba(255, 51, 153, 1)",
    "rgba(102, 204, 204, 1)",
    "rgba(204, 102, 0, 1)",
    "rgba(102, 0, 204, 1)",
  ];
  const colorPaletteGradient = [
    "rgba(250,174,50,0)",
    "rgba(75,192,192,0)",
    "rgba(255,99,132,0)",
    "rgba(54,162,235,0)",
    "rgba(255,206,86,0)",
    "rgba(153,102,255,0)",
    "rgba(255, 128, 0, 0)",
    "rgba(0, 128, 128, 0)",
    "rgba(255, 51, 51, 0)",
    "rgba(102, 204, 0, 0)",
    "rgba(255, 51, 153, 0)",
    "rgba(102, 204, 204, 0)",
    "rgba(204, 102, 0, 0)",
    "rgba(102, 0, 204, 0)",
  ];

  const labels = [
    info[0]?.week0?.name,
    info[0]?.week1?.name,
    info[0]?.week2?.name,
    info[0]?.week3?.name,
    info[0]?.week4?.name,
  ];

  // Prepare the datasets array
  const datasets = info.map((item, index) => ({
    label: item.name,
    data: [
      item?.week0?.data,
      item?.week1?.data,
      item?.week2?.data,
      item?.week3?.data,
      item?.week4?.data,
    ],
    borderColor: colorPalette[index % colorPalette.length],
    backgroundColor: (context) => {
      const ctx = context.chart.ctx;
      let heightChart = ctx.canvas.height - ctx.canvas.height * 0.1;
      const gradient = ctx.createLinearGradient(0, 0, 0, heightChart);
      gradient.addColorStop(0, colorPalette[index % colorPalette.length]);
      gradient.addColorStop(
        1,
        colorPaletteGradient[index % colorPaletteGradient.length]
      );
      return gradient;
    },
    borderWidth: 2,
    fill: true,
    lineTension: 0,
    pointStyle: "circle",
    pointRadius: 3,
    pointHoverRadius: 15,
  }));

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        display: true,
        labels: {
          font: {
            size: 12,
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };

  return (
    <div style={{ maxWidth: "70vw", height: "300px", display: "flex" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
