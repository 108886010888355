import { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { filterStatus } from "../storage/filterSlice";

const useFilter = () => {

  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);
  const filter = useSelector((state) => state.filter.show);

  // useEffect(() => {
  //   console.log('useEffetc re ran')
  //   if (filterOpen) {
  //     dispatch(filterStatus("open"));
  //   } else {
  //     dispatch(filterStatus("closed"));
  //   }
  // }, [filterOpen, dispatch]);

  const handleToggleFilter = () => {
    // setFilterOpen(prevFilterOpen => !prevFilterOpen);
    if(filter === 'closed'){
      dispatch(filterStatus("open"));
      setFilterOpen(true)
    }else if(filter === 'open'){
      dispatch(filterStatus("closed"));
      setFilterOpen(false)
    }
  };

  return { handleToggleFilter };
};

export default useFilter;
