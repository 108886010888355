import { createSlice } from "@reduxjs/toolkit";

export const filterMoreDetailsSlice = createSlice({
  name: "filterMoreDetails",
  initialState: {
    filterAvailabilityStatus: [],
    auxAvailability: [],
  },
  reducers: {
    filterAvailabilityStatus: (state, action) => {
      state.filterAvailabilityStatus = action.payload;
    },
    setAuxAvailability:(state, action) => {
        state.auxAvailability = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
    filterAvailabilityStatus,
    setAuxAvailability
} = filterMoreDetailsSlice.actions;

export default filterMoreDetailsSlice.reducer;
