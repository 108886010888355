import React from 'react';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import { GridCell } from "@mui/x-data-grid-premium";

const TraceUpdates = React.forwardRef((props, ref) => {
  const { Component, ...other } = props;
  const rootRef = React.useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  React.useEffect(() => {
    const root = rootRef.current;
    root.classList.add("updating");
    root.classList.add("updated");

    const timer = setTimeout(() => {
      root.classList.remove("updating");
    }, 360);

    return () => {
      clearTimeout(timer);
    };
  });

  return <Component ref={handleRef} {...other} />;
});

let CellWithTracer = React.forwardRef((props, ref) => {
    return <TraceUpdates ref={ref} Component={GridCell} {...props} />;
  })

  export default CellWithTracer
