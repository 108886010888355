import React, { useState } from "react";
import "../../styles.scss";
import logo from "../../assets/Original Logo.svg";
import "./login.scss";
import { useDispatch } from "react-redux";
import {
  authStatus,
  setGroupsProperty,
  setPropertiesUser,
  setToken,
} from "../../storage/authSlice";
import { useSearchParams,useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { adminLogin } from "../../api/authUtils";
import { resetFilters } from "../../storage/filterSlice";
import { updateForgetPassword } from "../../api/api";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate()
  const [userPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [searchParams] = useSearchParams();

  const sendInfo = async(e) => {
    e.preventDefault();
    // const fetchLogin = async () => {
    //   /*try {
    //     const fetchedLogin = await adminLogin(confirmPassword, userPassword);
    //     if (fetchedLogin?.data?.user?.hasAccess) {
    //       setHasError(false);
    //       window.localStorage.setItem(
    //         "@property-token",
    //         fetchedLogin?.data?.token
    //       );
    //       const userData = fetchedLogin?.data?.user;
    //       console.log(fetchedLogin?.data);
    //       const updatedStore = { userData, auth: true };
    //       window.localStorage.setItem(
    //         "@property-userData",
    //         JSON.stringify(updatedStore)
    //       );
    //       dispatch(setPropertiesUser(userData.properties));
    //       dispatch(setGroupsProperty(userData.propertyGroups));
    //       dispatch(setToken(userData.id));
    //       dispatch(authStatus(true));
    //       dispatch(resetFilters());
    //       redirect("/overview");
    //     } else {
    //       console.log("error");
    //       setHasError(true);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     setHasError(true);
    //   }*/
    // };
    // fetchLogin();

    if(userPassword !== confirmPassword){
      setErrorMessage('Password does not match')
      setHasError(true)
      return
    }
    let email = searchParams.get('email')
    let token = searchParams.get('token')
let response = await updateForgetPassword(email, token, userPassword)

if(response?.errors){
  setErrorMessage(response?.msg)
  setHasError(true)
  return
}
return navigate('/login')

  };

  const onChangePassword = (e) => setPassword(e.target.value);
  const onChangeConfirmPassword = (e) => {
      setConfirmPassword(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setHasError(false);
  };

  return (
    <>
      <div className="container-Login">
        <Snackbar
          autoHideDuration={3000}
          onClose={handleClose}
          open={hasError}
          sx={{ height: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {/* The email or password is incorrect */}
            {errorMessage}
          </Alert>
        </Snackbar>
        <div className="card-Login">
          <img src={logo} />
          <div className="form">
            <label>Password</label>
            <input onChange={onChangePassword} type="password"></input>
            <br />
            <label>Confirm Password</label>
            <input onChange={onChangeConfirmPassword} type="password"></input>
            <button onClick={sendInfo}>RESET</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
