import React, { useState, useEffect } from "react";
import "./filter.scss";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { useSelector, useDispatch } from "react-redux";
import {
  filterStatus,
  setAllProperties,
  tableFilter,
} from "../../storage/filterSlice";
import { GroupsForm } from "../groupsForm";
import { PropertiesForm } from "../propertiesForm";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getUser } from "../../api/authUtils";
import { setGroupsProperty, setPropertiesUser } from "../../storage/authSlice";
import { saveLeasingData, saveOccupancyData, saveMaintenanceData, saveCollectionsData, saveBudgetData } from "../../storage/thresholdsSlice";
import { CircularProgress } from "@mui/material";
import useFilter from "../../hooks/useFilter";

const Filter = () => {
  const properties = useSelector((state) => state.auth.properties);
  const groups = useSelector((state) => state.auth.groupsProperty);
  const filter = useSelector((state) => state.filter.show);
  const filterProperties = useSelector(
    (state) => state.filter.filterProperties
  );
  const filterGroups = useSelector((state) => state.filter.filterGroups);
  const ENUM_OPTIONS = {
    DO_NOTHING:0,
    CHECKED:1,
    UNCHECKED:2,
  }

  const [filterOpen, setFilterOpen] = useState(false);
  const [checked, setChecked] = useState(ENUM_OPTIONS.DO_NOTHING);
  const [checkedGroup, setCheckedGroup] = useState(ENUM_OPTIONS.DO_NOTHING);
  const [show, setShow] = useState(false);
  const [loaderProperties, setLoaderProperties] = useState(true);
  const [loaderGroups, setLoaderGroups] = useState(true);
  const [propertiesSelected, setPropertiesSelected] = useState(properties)
  const [groupsSelected, setGroupsSelected] = useState(groups)
  

  const dispatch = useDispatch();

  let {handleToggleFilter} = useFilter()

  const handleFilter = () => {
    //first filter, get properties and groups has value like 1
    const filterP = filterProperties.filter((item) => item.value === 1);
    const filterG = filterGroups.filter((item) => item.value === 1);
    //validate if any property is selected
    if (filterP.length === 0 && filterG.length === 0) {
      setShow(true);
      return;
    }

    let uniqueArray = [];
    //only groups
    if (filterG.length > 0 && filterP.length === 0) {
      uniqueArray = savePropertiesFromGroups(filterG);
      const arrayDeIds = uniqueArray.map(object => object.id);
      const jsonStringProperties = JSON.stringify(arrayDeIds);
      localStorage.setItem("@properties-filter", jsonStringProperties);
      dispatch(tableFilter(uniqueArray));
    }
    //both
    if (filterP.length > 0 && filterG.length > 0) {
      uniqueArray = savePropertiesFromGroupsAndProperties(filterP, filterG);
      const arrayDeIds = uniqueArray.map(object => object.id);
      const jsonStringProperties = JSON.stringify(arrayDeIds);
      localStorage.setItem("@properties-filter", jsonStringProperties);

      dispatch(tableFilter(uniqueArray));
    }
    //only properties
    if (filterP.length > 0 && filterG.length === 0) {
      const arrayDeIds = filterP.map(object => object.id);
      const jsonStringProperties = JSON.stringify(arrayDeIds);
      localStorage.setItem("@properties-filter", jsonStringProperties);

      dispatch(tableFilter(filterP));
    }

    // Convert the array to a JSON string
    const jsonStringProperty = JSON.stringify(filterP);
    const jsonStringGroup = JSON.stringify(filterG);
    // Save the JSON string in localStorage under a specific key (e.g., 'uniquePropertiesData')
    localStorage.setItem("@property-filter", jsonStringProperty);
    localStorage.setItem("@group-filter", jsonStringGroup);

    setFilterOpen(!filterOpen);
  };

  const savePropertiesFromGroups = (inputArray) => {
    const uniquePropertiesArray = inputArray.flatMap(
      (group) => group.properties
    );
    const uniquePropertyIds = new Set();
    const filteredUniquePropertiesArray = [];

    for (const property of uniquePropertiesArray) {
      if (!uniquePropertyIds.has(property.propertyID)) {
        uniquePropertyIds.add(property.propertyID);
        filteredUniquePropertiesArray.push(property);
      }
    }
    return filteredUniquePropertiesArray;
  };

  const savePropertiesFromGroupsAndProperties = (
    propertiesArray,
    groupArray
  ) => {
    const uniquePropertiesArray = groupArray.flatMap(
      (group) => group.properties
    );
    //concat
    const mergeArrays = [...propertiesArray, ...uniquePropertiesArray];

    const uniquePropertyIds = new Set();
    const filteredUniquePropertiesArray = [];

    for (const property of mergeArrays) {
      if (!uniquePropertyIds.has(property.propertyID)) {
        uniquePropertyIds.add(property.propertyID);
        filteredUniquePropertiesArray.push(property);
      }
    }
    return filteredUniquePropertiesArray;
  };

  const getAllProperties = (properties, groups) => {
    const allProperties = groups.flatMap((group) =>
      group.properties.map((property) => ({
        propertyName: property.propertyName,
        propertyID: property.propertyID,
      }))
    );
    let concatArrays = [...allProperties, ...properties];

    const uniqueProperties = [];
    const uniquePropertyIDs = new Set();

    concatArrays.forEach((property) => {
      if (!uniquePropertyIDs.has(property.propertyID)) {
        uniqueProperties.push(property);
        uniquePropertyIDs.add(property.propertyID);
      }
    });

    dispatch(setAllProperties(uniqueProperties));
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
  };

  const handleDisplayFilterGroups = () =>{
    if(loaderGroups) {return(
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0",
        }}
      >
        <CircularProgress />
      </div>
    )} else{ 
      return(
      <GroupsForm
        filter={true}
        selected={groupsSelected}
        groups={groups}
        checkAll={checkedGroup}
      />
    )}
  }

  const handleDisplayFilterProperties = () =>{
    if(loaderProperties) {return(
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0",
        }}
      >
        <CircularProgress />
      </div>
    )} else{ 
      return(
        <PropertiesForm
          filter={true}
          selected={propertiesSelected}
          properties={properties}
          checkAll={checked}/>
    )}
  }

  useEffect(() => {
    if (filterOpen) dispatch(filterStatus("open"));
    else dispatch(filterStatus("closed"));
  }, [filterOpen, dispatch]);

  useEffect(() => {
    if (filter === "open") setFilterOpen(true);
    else setFilterOpen(false);
  }, [filter]);

  useEffect(() => {
    if (properties !== undefined) getAllProperties(properties, filterGroups);
  }, [properties, filterGroups]);

  useEffect(() => {
    // Retrieve the data from localStorage
    const storedDataProperty = localStorage.getItem("@property-filter");
    const storedDataGroup = localStorage.getItem("@group-filter");

    // Parse the JSON string back into an array
    const retrievedArrayProperty = JSON.parse(storedDataProperty);
    const retrievedArrayGroup = JSON.parse(storedDataGroup);

    const getUserData = async () => {
      await getUser().then((res)=>{
        dispatch(setPropertiesUser(res?.user?.properties));
        dispatch(setGroupsProperty(res?.user?.propertyGroups));
        dispatch(saveLeasingData(res.threshold[0].section[0].subsection));
        dispatch(saveOccupancyData(res.threshold[0].section[1].subsection));
        dispatch(saveMaintenanceData(res.threshold[0].section[2].subsection));
        dispatch(saveCollectionsData(res.threshold[0].section[3].subsection));
        dispatch(saveBudgetData(res.threshold[0].section[4].subsection));

        if(retrievedArrayProperty !== null || retrievedArrayGroup !== null){
          //has some property selected and saved in to localstorage
          setPropertiesSelected(retrievedArrayProperty)
          setGroupsSelected(retrievedArrayGroup)

          //if all properties are checked
          if(retrievedArrayProperty.length === res.user.properties.length){
            setChecked(ENUM_OPTIONS.CHECKED)
          } 
          if(retrievedArrayGroup.length === res.user.propertyGroups.length){
            setCheckedGroup(ENUM_OPTIONS.CHECKED)
          }
          
          //dispatch(tableFilter(retrievedArrayProperty)); no me acuerdo por que
        }
        if(retrievedArrayProperty === null && retrievedArrayGroup === null){
          //doesnt have localstorage (only first time) check all properties
          setChecked(ENUM_OPTIONS.CHECKED)
          setCheckedGroup(ENUM_OPTIONS.CHECKED)
          setPropertiesSelected(res?.user?.properties)
          setGroupsSelected(res?.user?.propertyGroups)
        }

        setLoaderProperties(false);
        setLoaderGroups(false);
      })
     
    };

    getUserData();
  }, [dispatch]);

  const handleButtonLogicProperties = () => {
    if(checked === ENUM_OPTIONS.CHECKED){
      setChecked(ENUM_OPTIONS.UNCHECKED)
    }
    if(checked === ENUM_OPTIONS.UNCHECKED){
      setChecked(ENUM_OPTIONS.CHECKED)
    }
    if(checked === ENUM_OPTIONS.DO_NOTHING){
      setChecked(ENUM_OPTIONS.CHECKED)
    }
  }

const handleButtonLogicGroups = () => {
  if(checkedGroup === ENUM_OPTIONS.CHECKED){
    setCheckedGroup(ENUM_OPTIONS.UNCHECKED)
  }
  if(checkedGroup === ENUM_OPTIONS.UNCHECKED){
    setCheckedGroup(ENUM_OPTIONS.CHECKED)
  }
  if(checkedGroup === ENUM_OPTIONS.DO_NOTHING){
    setCheckedGroup(ENUM_OPTIONS.CHECKED)
  }
}
  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        onClose={handleClose}
        open={show}
        sx={{ height: "100%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Please select at least one property to proceed
        </Alert>
      </Snackbar>
      <div className={`filter-container ${filter}`}>
        <div className="filter-top">
          <CancelTwoToneIcon
            fontSize="large"
            onClick={() => {setFilterOpen(!filterOpen); handleToggleFilter()}}
            className="btn"
          />
        </div>
        <div className="filter-content">
          {properties?.length !== 0 ? (
            <div className="filter-card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <h2>Property</h2>
                <button onClick={handleButtonLogicProperties}>
                  {checked === ENUM_OPTIONS.CHECKED || checked === ENUM_OPTIONS.DO_NOTHING ? "Uncheck" : "Check"} All
                </button>
              </div>
              <div className="line"></div>
              {handleDisplayFilterProperties()}
            </div>
          ) : (
            <></>
          )}
          {groups?.length !== 0 ? (
            <div className="filter-card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <h2>Groups</h2>
                <button onClick={handleButtonLogicGroups}>
                  {checkedGroup=== ENUM_OPTIONS.CHECKED || checkedGroup=== ENUM_OPTIONS.DO_NOTHING ? "Uncheck" : "Check"} All
                </button>
              </div>
              <div className="line"></div>
              {handleDisplayFilterGroups()}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="filter-bottom">
          <button onClick={handleFilter}>SAVE CHANGES</button>
        </div>
      </div>
    </>
  );
};

export default Filter;