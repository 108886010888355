import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles.scss";

const Users = ({ data }) => {
  const [height, setHeight] = useState("430px");

  useEffect(() => {
    if (data?.length <= 10) {
      let len = (data?.length + 1) * 43;
      setHeight(len.toString() + "px");
    }
  }, [data]);

  return (
    <div className="container-table" style={{ height: height }}>
      <div className="wrapper">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Title</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className={`${index%2 === 0 ? 'white' : ''}`}>{item?.firstName + " " + item?.lastName}</td>
                <td>{item?.title}</td>
                <td>{item?.email}</td>
                <td>
                  <Link className="edit" to={`/editUser/${item.id}`}>EDIT</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
