import React from "react";

const SelectColor = ({ question }) => {
  return (
    <>
      <div className="item-container">
        <div className="item-question">{question}</div>
        <div className="item-colors">
          <div className="line">
            <div className="color green"></div>
            <div className="sign">≥</div>
            <input type="text"></input>
          </div>
          <div className="line">
            <div className="color yellow"></div>
            <div className="sign">{"<"}</div>
            <input type="text"></input>
          </div>
          <div className="line">
            <div className="color red"></div>
            <div className="sign">{"<"}</div>
            <input type="text"></input>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectColor;
