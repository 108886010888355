import React, {useState, useRef, useEffect} from "react";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import CellWithTracer from "../../../../components/cellTracer/CellTracer";

const CollectionDetailTable = ({ data }) => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const vwToPx = (vw) => (vw * width) / 100;

  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "Delinquencies"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
    handleCheckFlagforUnits
  } = useNoteVisibility(listNotes, loader);
  const {getThresholdData} = useThreshold({view:'collections', table: 'MoreDetailsDelinquencies', loader})

  const columnNames = [
    {name: "Property", type:'number', width:vwToPx(20)},
    {name: "BLDG Unit", type:'number', width:vwToPx(5)},
    {name: "Resident", type:'number', width:vwToPx(10)},
    {name: "Lease Status", type:'number', width:vwToPx(8)},
    {name: "0-30 Days", type:'number', width:vwToPx(8)},
    {name: "31-60 Days", type:'number', width:vwToPx(8)},
    {name: "61-90 Days", type:'number', width:vwToPx(8)},
    {name: "90+ Days", type:'number', width:vwToPx(8)},
    {name: "Balance", type:'number', width:vwToPx(8)},
    {name: "Last Delinquency Note", width:vwToPx(30)},
  ];

  const thresHoldValues = {
    first:{
      thresHoldValue:'0-30Days'
    },
    second:{
      thresHoldValue:'31-60Days'
    },
    third:{
      thresHoldValue:'61-90Days'
    },
    fourty:{
      thresHoldValue:'90+Days'
    },
    fourty:{
      thresHoldValue:'90+Days'
    },
    note:{
      thresHoldValue:'LastDelinquencyNoteDateRange'
    },
  }

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(data, columnNames);

  let columns = columenGen();
  return (
    <div className="content" ref={elementRef}>
        {showComponent && (
          <div
            style={{
              position: "absolute",
              left: componentPosition.x,
              top: componentPosition.y,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              padding: "10px",
              zIndex: "10",
              borderRadius: "10px",
            }}
          >
            <Note
              notes={notes}
              columnNames={notesColumnNameGen()}
              table="Delinquencies"
            />
          </div>
        )}
 <DataGridPremium
             sx={{
               '&&& .updated': {
                 transition: (theme) =>
                 theme.transitions.create(['background-color', 'outline'], {
                   duration: theme.transitions.duration.standard,
                  }),
                },
                '&&& .updating': {
                  backgroundColor: 'rgb(92 199 68 / 20%)',
                  outline: '1px solid rgb(92 199 68 / 35%)',
                  outlineOffset: '-1px',
                  transition: 'none',
                },
                '&&& .MuiDataGrid-cellContent': {
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                },
              }}
              className="grid-style"
              slots={{
                toolbar: ExportToolbar, cell:CellWithTracer,
              }}
              rows={data}
              columns={columns}
              getRowHeight={()=> 'auto'}
        disableColumnMenu={true}
        hideFooter
        // disableVirtualization
        disableColumnSelector
        getCellClassName={(params) => {
          return `${thresHolds(params, getThresholdData, thresHoldValues)} ${handleCheckFlagforUnits(params.row.propertyID, params.colDef.colNum, params.row.bdlg)}`
        }}
          onCellClick={(cell, event) => {
            handleNote(
              cell.row.propertyID,
              cell.colDef.colNum,
              cell.formattedValue,
              cell.row.bdlg
              )(event);
            }}
      />
    </div>
  );
};

export default CollectionDetailTable;

//Legacy Code
// import React from "react";
// import { money } from "../../../../functions/decimals";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const CollectionDetailTable = ({ data }) => {
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "Delinquencies"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//     handleCheckFlagforUnits
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'collections', table: 'MoreDetailsDelinquencies', loader})
//   const columnNames = [
//     "Property",
//     "BLDG Unit",
//     "Resident",
//     "Lease Status",
//     "0-30 Days",
//     "31-60 Days",
//     "61-90 Days",
//     "90+ Days",
//     "Balance",
//     "Last Delinquency Note",
//   ];
//   return (
//     <div className="content">
//       <table className="table">
//         {showComponent && (
//           <div
//             style={{
//               position: "absolute",
//               left: componentPosition.x,
//               top: componentPosition.y,
//               backgroundColor: "#fff",
//               border: "1px solid #ccc",
//               padding: "10px",
//               zIndex: "10",
//               borderRadius: "10px",
//             }}
//           >
//             <Note
//               notes={notes}
//               columnNames={columnNames}
//               table="Delinquencies"
//             />
//           </div>
//         )}

//         <thead>
//           <tr>
//             {columnNames.map((column) => {
//               return (
//                 <th className="subparent" key={column}>
//                   {column}
//                 </th>
//               );
//             })}
//           </tr>
//         </thead>
//         <tbody>
//           {data?.map((item, index) => (
//             <tr key={index}>
//               <td
//                 // onClick={handleNote(item?.propertyID, 0)}
//                 className={`${handleCheckFlag(item?.propertyID, 0)} ${
//                   index % 2 === 0 ? "white" : ""
//                 }`}
//               >
//                 {item?.name}
//               </td>
//               <td
//                 // onClick={handleNote(item?.propertyID, 1)}
//                 className={handleCheckFlag(item?.propertyID, 1)}
//               >
//                 {item?.bdlg}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 2, item?.resident, item?.bdlg)}
//                 className={handleCheckFlagforUnits(item?.propertyID, 2, item?.bdlg)}
//               >
//                 {item?.resident}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 3, item?.leaseStatus, item?.bdlg)}
//                 className={handleCheckFlagforUnits(item?.propertyID, 3, item?.bdlg)}
//               >
//                 {item?.leaseStatus}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 4, item?.first, item?.bdlg)}
//                 className={`${handleCheckFlagforUnits(item?.propertyID, 4, item?.bdlg)}
//                 ${getThresholdData(parseInt(item?.first), '0-30Days')}`}
//                 style={{textAlign:'right'}}
//               >
//                 {money(item?.first)}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 5, item?.second, item?.bdlg)}
//                 className={`${handleCheckFlagforUnits(item?.propertyID, 5, item?.bdlg)}
//                 ${getThresholdData(parseInt(item?.second), '31-60Days')}`}
//                 style={{textAlign:'right'}}
//               >
//                 {money(item?.second)}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 6, item?.third, item?.bdlg)}
//                 className={`${handleCheckFlagforUnits(item?.propertyID, 6, item?.bdlg)}
//                 ${getThresholdData(parseInt(item?.third), '61-90Days')}`}
//                 style={{textAlign:'right'}}
//               >
//                 {money(item?.third)}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 7, item?.fourty, item?.bdlg)}
//                 className={`red ${handleCheckFlagforUnits(item?.propertyID, 7, item?.bdlg)} ${getThresholdData(parseInt(item?.fourty), '90+Days')}`}
//                 style={{textAlign:'right'}}
//               >
//                 {money(item?.fourty)}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 8, item?.balance, item?.bdlg)}
//                 className={handleCheckFlagforUnits(item?.propertyID, 8, item?.bdlg)}
//                 style={{textAlign:'right'}}
//               >
//                 {money(item?.balance)}
//               </td>
//               <td
//                 onClick={handleNote(item?.propertyID, 9, item?.note, item?.bdlg)}
//                 className={`${getThresholdData(parseInt(item?.range), 'LastDelinquencyNoteDateRange')} ${handleCheckFlagforUnits(item?.propertyID, 9, item?.bdlg)}`}
//               >
//                 {item?.note}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default CollectionDetailTable;
