import React from "react";
import SelectColor from "../components/select";

const OccupancyTab = () => {
  return (
    <>
      <SelectColor question="What is your target occupancy?" />
      <SelectColor question="How many days do you expect a unit to be vacant between residents?" />
    </>
  );
};

export default OccupancyTab;
